.holding {
  position: relative;
  z-index: 1;

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    padding-top: ac(70px, 40px);
    padding-bottom: 30px;
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    @mixin aspect-ratio 2354, 718;
    width: 100%;
    max-width: ac(825px, 600px);

    &:not(:last-child) {
      margin-bottom: ac(50px, 40px);
    }
  }

  &__text {
    width: 100%;
    max-width: ac(1113px, 700px);

    p {
      color: var(--cl-white);
      text-align: center;
      font-size: ac(40px, 24px);
      font-style: normal;
      font-weight: 300;
      line-height: 140%;

      a {
        font: inherit;
        color: var(--cl-white);
        transition: color 0.3s ease;

        &:hover {
          color: var(--cl-primary);
        }
      }

      &:not(:last-child) {
        margin-bottom: ac(36px, 16px);
      }
    }

    &--form {
      p {
        line-height: normal;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 32px);
    }
  }

  &__form {
    margin-top: ac(-30px, -5px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    .meta-input {
      width: 340px;
    }

    @mixin media 551 {
      .btn {
        width: 100%;
      }

      .meta-input {
        width: 100%;
      }
    }
  }

  &__bottom {
    padding-top: ac(72px, 40px);
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: ac(46px, 20px);
    grid-row-gap: 10px;

    @mixin media 551 {
      justify-content: center;
    }
  }

  &__bottom-text,
  &__bottom-link {
    color: var(--cl-grey);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__bottom-link {
    position: relative;
    transition: all 0.3s ease;

    &:before {
      bottom: 0;
      right: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: var(--cl-grey);
      transition: all 0.3s ease;
      content: '';
      position: absolute;
    }

    &:hover {
      color: var(--cl-white);
      transform: scale(1.05);
      &:before {
        background: var(--cl-white);
      }
    }
  }
}
