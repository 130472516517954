.pagination-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  height: ac(44px, 40px);
  min-width: ac(44px, 40px);
  padding-left: 4px;
  padding-right: 4px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;

  i {
    font-size: ac(20px, 16px);
    color: var(--cl-blue);
    transition: color 0.3s ease;
  }

  &.pagination-btn-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    &:not(.pagination-btn-disabled) {
      border-color: var(--cl-navy);
    }
  }

  &.pagination-next {
    /*margin-left: ac(16px, 8px);*/

    i {
      transform: rotate(-90deg);
    }
  }

  &.pagination-prev {
    /*margin-right: ac(16px, 8px);*/

    i {
      transform: rotate(90deg);
    }
  }
}

.pagination-num {
  text-align: center;
  font-size: ac(20px, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  cursor: pointer;
  transition: all 0.3s ease;
  height: ac(64px, 40px);
  min-width: ac(64px, 40px);
  padding-left: 4px;
  padding-right: 4px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    color: var(--cl-black);
    opacity: 0.7;
    transition: all 0.3s ease;
  }

  &.active {
    cursor: not-allowed;
    border-color: var(--cl-pink);
    background-color: var(--cl-blue-grey);
    span {
      opacity: 1;
    }
  }

  &.pointer-events-none {
    pointer-events: none;
    min-width: 28px;
    width: 28px;
  }

  &:hover {
    &:not(.active) {
      span {
        opacity: 0.5;
        color: var(--cl-black);
      }
    }
  }
}

.pagination {
  margin-top: ac(60px, 35px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ac(8px, 4px);
}
