.clients {
  padding-top: ac(120px, 40px);
  padding-bottom: ac(98px, 40px);
  overflow: hidden;
  position: relative;
  z-index: 5;

  &__marquee {
    margin-bottom: ac(20px, 16px);
  }

  .js-marquee {
    gap: ac(20px, 16px);
    display: flex;
  }
  &__suptitle {
    margin-bottom: 17px;
  }
  &__title {
    margin-bottom: ac(59px, 35px);
  }

  &__logo {
    display: grid;
    place-items: center;
    padding: 5px;
    background-color: #fbfcfe;
    width: 304px;
    height: 172px;
    border: 1px solid var(--cl-blue-grey);
    border-radius: 20px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  @media (min-width: 1536px) {
    .cont {
      max-width: 100%;
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    .clients {
      &__logo {
        padding: 20px;
        width: 200px;
        height: 116px;
      }
    }
  }

  @media (max-width: 640px) {
    .clients {
      &__wrapper {
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__suptitle {
        margin-left: 0;
        order: 1;
      }

      &__title {
        margin-left: 0;
        order: 2;
      }

      &__marquee {
        order: 3;
      }
    }
  }

  @media (max-width: 360px) {
    .btn {
      margin-top: 28px;
      order: 4;
    }
  }
}
