.testimonial-card {
  background: var(--cl-white);
  border: 4px solid var(--cl-blue-grey);
  border-radius: 24px;
  padding-left: ac(36px, 18px);
  padding-right: ac(20px, 16px);
  padding-top: 12px;
  padding-bottom: ac(36px, 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__logo {
    --arw: 157;
    --arh: 79;
    width: ac(157px, 140px);

    img {
      object-fit: contain;
      object-position: left;
    }

    &:not(:last-child) {
      margin-bottom: ac(26px, 24px);
    }
  }

  &__text {
    line-height: 1.3;

    &:not(:last-child) {
      margin-bottom: ac(27px, 24px);
    }
  }

  &__author {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(16px, 12px);
  }

  &__author-image {
    width: ac(70px, 64px);
    border-radius: 50%;

    &:after {
      position: absolute;
      inset: -1px;
      z-index: 1;
      border: 4px solid var(--cl-blue-grey);
      border-radius: 50%;
      content: '';
    }
  }

  &__author-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    grid-row-gap: ac(7px, 6px);
  }

  &__author-title {
    font-family: var(--font-second);
    font-weight: 700;
    font-size: ac(20px, 18px);
    line-height: normal;
  }

  &__author-subtitle {
    font-weight: 400;
    font-size: ac(18px, 16px);
    line-height: normal;
    color: var(--cl-blue);
    opacity: 0.8;
  }
}
