.line-decor {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 18;
  overflow: hidden;
  mask-image: radial-gradient(white, black);
  pointer-events: none;

  &::before {
    content: '';
    display: block;
    padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
  }

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &[data-view] {
    opacity: 0;
    transition: opacity 1s ease;

    &.animated {
      opacity: 1;

      svg {
        path {
          fill: transparent;
          animation: dash 1.5s linear forwards;
          /*animation-direction: reverse;*/
        }
      }
    }
  }
}

@keyframes dash {
  100% {
    stroke-dashoffset: 0;
  }
}
