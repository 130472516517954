.first-section {
  position: relative;
  z-index: 10;
  overflow: hidden;
  background: var(--gradient);

  &__bg {
    position: absolute;
    inset: 0;
    z-index: 2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    min-height: 100vh;
    height: auto;
    padding-top: 234px;
    padding-bottom: 170px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 5;
    position: relative;

    @media (max-width: 1024px) {
      min-height: 600px;
      @media (min-width: 641px) {
        height: auto;
      }
    }

    @mixin media 551 {
      padding-bottom: 40px;
      height: auto;
      min-height: 100vh;
    }
  }

  &__decor {
    @mixin aspect-ratio 1632, 683;
    width: 131.4%;
    min-width: 600px;
    position: absolute;
    right: ac(-826px, -300px);
    bottom: ac(-124px, -40px);
    opacity: 0.2 !important;
    z-index: -1;
  }

  &__logo {
    @mixin aspect-ratio 531.94, 116;
    max-width: ac(532px, 400px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(23px, 24px);
    }
  }

  &__title {
    width: 100%;
    font-family: var(--font-second);

    font-size: ac(96px, 46px);
    line-height: 1.2;
    font-weight: 700;

    color: var(--cl-white);

    span {
      &[data-word='.'] {
        color: var(--cl-pink);
      }
    }

    @media (max-width: 360px) {
      font-size: 40px;
    }
  }

  &__text {
    font-weight: 500;
    font-size: ac(40px, 20px);
    line-height: normal;

    color: var(--cl-blue-grey);

    padding-top: ac(32px, 18px);
  }

  &__scroll-btn {
    --size: ac(70px, 59px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: ac(80px, 60px);
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cl-primary);
    transition: background-color 0.3s ease;
    border: 1px solid var(--cl-primary);
    border-radius: 50%;
    animation: pulse 1s infinite;

    i {
      font-size: ac(28px, 24px);
      color: var(--cl-white);
    }

    &:hover {
      background-color: var(--cl-black);
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(242, 204, 215, 0.5);
    }
    70% {
      box-shadow: 0 0 0 12px rgba(242, 204, 215, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(242, 204, 215, 0);
    }
  }

  &__right {
    border: 2px solid var(--cl-pink);
    padding: ac(60px, 24px) ac(48px, 24px);

    border-radius: ac(32px, 16px);

    &-content {
      color: var(--cl-white);
    }
  }

  &.internal-type {
    .first-section {
      &__wrap {
        min-height: calc(100vh - ac(178px, 100px));
        height: auto;
        padding-top: ac(234px, 120px);
        padding-bottom: ac(174px, 120px);

        @media (max-width: 1024px) {
          min-height: 600px;
          @media (min-width: 641px) {
            height: auto;
          }
        }

        @media (min-height: 1090px) {
          min-height: 900px;
        }

        @media (max-width: 640px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
      }
    }

    .line-decor {
      bottom: ac(115px, 65px);

      @media (max-height: 870px) {
        bottom: 40px;
      }
    }
  }

  &.team-page {
    .line-decor {
      bottom: 0;
      z-index: 100;
    }

    .first-section {
      &__wrap {
        min-height: calc(100vh - ac(168px, 100px));
        height: auto;
        padding-top: ac(234px, 120px);
        padding-bottom: ac(174px, 120px);

        @media (min-height: 1090px) {
          min-height: 900px;
        }
      }
    }

    .first-section__title {
      text-align: start;
    }
  }

  &.areas-page {
    .line-decor {
      bottom: ac(130px, 80px);

      @media (max-height: 870px) {
        bottom: 40px;
      }
    }

    .first-section {
      &__wrap {
        min-height: calc(100vh - ac(80px, 100px));
        height: auto;
        padding-top: ac(234px, 120px);
        padding-bottom: ac(174px, 120px);

        @media (min-height: 1090px) {
          min-height: 900px;
        }
      }

      &__text {
        @media (max-width: 640px) {
          br {
            display: none;
          }
        }
      }
    }

    .first-section__title {
      text-align: start;
    }
  }

  &.esmos-page {
    .line-decor {
      top: auto;
      bottom: ac(135px, 70px);
    }

    .first-section {
      &__wrap {
        min-height: calc(100vh - ac(80px, 100px));
        flex-direction: row;
        justify-content: space-between;
        padding-top: ac(234px, 120px);
        padding-bottom: ac(268px, 120px);

        gap: ac(64px, 32px);

        @media (min-height: 1090px) {
          min-height: 900px;
        }
      }

      &__title {
        white-space: nowrap;
        text-align: start;
      }

      &__content {
        width: 51.39%;
      }

      &__right {
        width: 49.61%;
        background: #00249599;
      }

      @media (max-width: 950px) {
        &__wrap {
          flex-direction: column;
        }

        &__content {
          width: 100%;
        }

        &__title {
          text-align: start;
        }

        &__right {
          width: 100%;
        }
      }
    }
  }

  &.jobs-page {
    .first-section {
      &__wrap {
        min-height: calc(100vh - ac(178px, 100px));
        padding-top: ac(194px, 120px);
        padding-bottom: ac(130px, 90px);

        display: flex;
        flex-direction: row;
        gap: 56px;

        @media (min-height: 1090px) {
          min-height: 900px;
        }

        @media (max-width: 640px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
      }

      &__title {
        text-align: start;
        font-weight: 700;
        font-size: 64px;
        line-height: 76.8px;

        padding-top: ac(24px, 16px);
      }

      &__text {
        display: flex;
        align-items: center;
        padding-top: ac(48px, 32px);

        &-btn {
          display: flex;
          align-items: center;
          justify-content: center;

          transition: 0.3s;

          &:hover {
            transform: scale(1.05);

            svg {
              fill: var(--cl-white);
            }

            svg path {
              fill: var(--cl-pink);
            }

            rect {
              stroke: var(--cl-pink);
            }
          }
        }

        p {
          font-weight: 500;
          font-size: ac(22px, 14px);
          line-height: 30.8px;

          margin: 0;

          padding-left: 12px;
        }
      }

      &__button {
        padding-left: ac(32px, 20px);

        .btn {
          background: var(--cl-white);

          &:hover {
            background: var(--cl-pink);
            border-color: var(--cl-pink);

            .btn__text {
              color: var(--cl-white);
            }
          }

          &__text {
            color: var(--cl-black);
          }
        }
      }

      &__content {
        width: 49.22%;

        &-published {
          display: flex;
          gap: ac(16px, 8px);

          font-weight: 500;
          font-size: ac(22px, 16px);
          line-height: 30.8px;

          color: var(--cl-white);

          span {
            opacity: 0.5;
          }
        }
      }

      &__right {
        width: 46.41%;
        background: #00249599;
        padding: ac(40px, 20px) ac(48px, 24px);

        &-content {
          border: none;

          p {
            padding-top: ac(14px, 10px);
            padding-bottom: ac(14px, 10px);

            border-bottom: 1px solid #edf0f84d;
          }
        }
      }
    }
  }
}
