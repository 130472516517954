.areas-section {
  padding-top: ac(8px, 20px);
  padding-bottom: ac(70px, 40px);
  overflow: hidden;

  &__wrap {
  }

  &__slider {
    width: 100%;
    padding: 0 ac(64px, 0px);

    .swiper {
      overflow: visible;
      margin-left: 0;

      @media (max-width: 640px) {
        width: 100%;
        max-width: 375px;
      }
    }

    .swiper-button {
      margin-top: ac(40px, 30px);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: ac(16px, 14px);
    height: auto;
  }

  &__item {
    width: 100%;
    display: grid;
    grid-template-columns: 41px repeat(1, minmax(0, 1fr));
    grid-column-gap: 10px;
    padding-right: ac(70px, 50px);
    position: relative;
    padding-bottom: ac(16px, 14px);
    border-bottom: 1px solid var(--cl-blue-grey);
    cursor: pointer;
    height: 100%;
    align-items: center;

    &:hover {
      .areas-section {
        &__item-link {
          opacity: 1;
        }
      }
    }
  }

  &__item-count {
    color: var(--cl-pink);
    font-size: ac(30px, 24px);
  }

  &__item-title {
    color: var(--cl-blue);
    --max-line-num: 3;
    font-size: ac(30px, 24px);
    @media (max-width: 390px) {
      &.heading-4 {
        font-size: 22px;
        letter-spacing: 0;
      }
    }

    @media (max-width: 374px) {
      &.heading-4 {
        font-size: 20px;
        letter-spacing: 0;
      }
    }
  }

  &__item-link {
    position: absolute;
    top: calc(50% - 7.5px);
    transform: translateY(-50%);
    right: 0;
    --size: ac(62px, 40px);
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(24px, 18px);
    color: var(--cl-white);
    cursor: pointer;
    background: var(--cl-pink);
    transition: opacity 0.3s ease;
    z-index: 10;
    opacity: 0;

    i {
      transform: rotate(-45deg);
    }

    /*&:hover {
      background-color: var(--cl-pink);
    }*/
  }

  + .clients-say-section {
    margin-top: 0;
  }
}
