.ethos-card {
  padding: 12px 32px 32px 32px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 5;
  min-height: 300px;
  max-height: 300px;
  --bg-height: 128px;

  &:before {
    background: var(--cl-white);
    height: calc(100% - var(--bg-height));
    border: 4px solid var(--cl-blue-grey);
    content: '';
    z-index: -1;
    border-bottom: none;
    border-radius: 16px 16px 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: rotate(180deg);
  }

  &__title {
    color: var(--cl-primary);
    font-weight: 700;
    font-size: ac(30px, 20px);
    line-height: 128%;
    margin-left: ac(104px, 90px);
    font-family: var(--font-second);
    min-height: ac(80px, 60px);
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(40px, 30px);
    }
  }

  &__content {
    font-size: ac(18px, 16px);
    line-height: 1.5;

    p,
    li {
      font-size: ac(18px, 16px);
      line-height: 1.5;
      padding-bottom: 1px;
    }

    &[data-scroll] {
      max-height: calc((ac(18px, 16px) * 1.51) * 5);

      @media (max-width: 500px) {
        max-height: calc((ac(18px, 16px) * 1.51) * 7);
      }
    }
  }

  &__image {
    position: absolute;
    left: ac(12px, 6px);
    top: ac(12px, 6px);
    --size: ac(80px, 60px);
    width: var(--size);
    height: var(--size);
  }

  &__bg {
    background-image: url('../../public/images/ethos-bg.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    aspect-ratio: 272 / 105;
    pointer-events: none;

    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    transform: translateZ(0);
    z-index: -2;
  }
}
