/* ----------------------- Common desktop header ----------------------- */

.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  padding: ac(24px, 14px) 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  }

  &.fixed {
    position: fixed;
  }

  /*&:not(.scrolled) {
    @media (min-width: 641px) {
      margin-top: calc(ac(40px, 32px) + ac(52px, 40px));
    }

    @media (max-width: 640px) {
      margin-top: ac(40px, 32px);
    }
  }*/

  &.hidden-logo {
    .logo {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.internal-header {
    @media (min-width: 1201px) {
      .logo {
        opacity: 1 !important;
        pointer-events: auto !important;
        display: block !important;
      }

      .menu .menu__list {
        margin-left: ac(91px, 80px);
      }
    }

    &.hidden-logo {
      @media (max-width: 1200px) {
        .logo {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }

  &.scrolled {
    &.hidden-logo {
      /*.logo {
        opacity: 1;
        pointer-events: auto;
      }*/
    }

    &.internal-header {
      @media (min-width: 1201px) {
        .logo {
          opacity: 0 !important;
          pointer-events: none !important;
        }
      }

      .menu__list {
        margin-left: 0;
      }
    }

    .menu__logo-item {
      /*transition-delay: 0.15s;*/
      max-width: 182px;
      opacity: 1;
      pointer-events: auto;

      .logo {
        opacity: 1 !important;
        pointer-events: auto !important;
      }
    }

    &:not(.active) {
      @media (min-width: 1201px) {
        padding: ac(32px, 14px) 0;
      }

      @media (max-width: 1200px) {
        -webkit-backdrop-filter: blur(16px);
        backdrop-filter: blur(16px);

        &:before {
          opacity: 0.6;
          background-color: var(--cl-black);
        }
      }

      .logo {
      }

      .menu__list::before {
        opacity: 1;
      }

      /*.menu__link {
        color: var(--cl-white);

        &:hover {
          color: var(--cl-pink);
        }
      }

      .burger,
      .menu-toggle {
        svg path {
          &:not(.active) {
            stroke: var(--cl-white);

            &:hover {
              stroke: var(--cl-pink);
            }
          }
        }
      }*/
    }
  }

  /*&.white-type {
    &:not(.scrolled) {
      &:not(.active) {
        .logo {
          .color-change {
            path {
              fill: var(--cl-cream);
            }
          }
        }

        .menu__link {
          color: var(--cl-white);

          &:hover {
            color: var(--cl-pink);
          }
        }

        .burger,
        .menu-toggle {
          svg path {
            &:not(.active) {
              stroke: var(--cl-white);

              &:hover {
                stroke: var(--cl-pink);
              }
            }
          }
        }
      }
    }
  }*/

  .logo {
    height: 100%;
    width: ac(182px, 172px);
    flex-shrink: 0;
    transition: all 0.25s ease;
    z-index: 100;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    img,
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
      transition: transform 0.25s ease;

      path {
        transition: all 0.3s ease;
        fill: var(--cl-white);
      }
    }

    &:hover {
      &:before {
        background-color: var(--cl-primary);
      }

      img,
      svg {
        /*transform: scale(1.06);*/

        path {
          fill: var(--cl-grey);
        }
      }
    }

    @media (min-width: 1201px) {
      display: none;
    }

    @media (max-width: 360px) {
      width: 180px;
    }
  }

  &__wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__wrap {
    @media (min-width: 1250px) {
      position: relative;

      .logo {
        position: absolute !important;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 40px;
      }

      .header__navbar {
        max-width: 100%;
        width: 100%;
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .menu .simplebar-content {
          justify-content: center;
        }
      }
    }
  }

  .burger {
    display: none;
  }

  &__navbar {
    display: flex;
    flex-grow: 1;

    .menu-list {
      display: flex;
      flex-grow: 1;
    }

    &__decor {
      display: none;
    }

    @media (min-width: 1201px) {
      margin-left: auto;
      max-width: calc(100% - ac(293px, -200px));
    }
  }

  &__socials {
    display: none;
  }

  &__navbar-decor {
    display: none;
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /*transition: opacity 0.2s ease;
    opacity: 0;*/

    @media (min-width: 1201px) {
      width: auto;
      flex: 1;
      align-items: center;

      .simplebar-content-wrapper,
      .simplebar-wrapper,
      .simplebar-mask {
        overflow: visible !important;
      }

      .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-column-gap: ac(20px, 14px);

        .menu-list {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: ac(32px, 16px);
        }

        &:after {
          display: none;
        }
      }
      .simplebar-track.simplebar-horizontal {
        display: none;
      }

      .menu {
        &__list {
          /*padding-left: 30px;
          padding-right: 30px;*/
          display: flex;
          align-items: center;
          justify-content: flex-end;
          /*grid-column-gap: ac(53px, 40px);*/
          /*background: var(--cl-black);*/
          /*border-radius: 24px;*/
          /*overflow: hidden;*/
          position: relative;
          z-index: 1;
          transition: margin-left 0.3s;

          &:before {
            position: absolute;
            inset: 0;
            border-radius: 1000px;
            background: var(--cl-blue);
            border: 1px solid rgba(255, 255, 255, 0.3);
            content: '';
            z-index: -1;
            box-shadow: 0px 0px 32px rgba(255, 255, 255, 0.2);
            transition: opacity 0.4s ease;
            opacity: 0;
          }

          .flying-item {
            position: absolute;
            top: 0;
            bottom: 0;
            border-radius: 1000px;
            background: transparent;
            border: 4px solid var(--cl-pink);
            left: 0;
            transition: width 0.3s ease, margin-left 0.6s ease,
              opacity 0.3s ease;
            pointer-events: none;
            opacity: 0;
          }

          .menu__item {
            position: relative;
            z-index: 5;
          }

          @media (max-width: 1280px) {
            margin-left: auto;
          }
        }
      }
    }

    &:not([data-simplebar='init']) {
      display: none;
    }
  }

  .menu__logo-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: 0;
    max-width: 0;
    transition: 0.3s;
    overflow: hidden;

    .logo {
      position: relative !important;
      top: auto !important;
      left: auto !important;
      transform: none !important;
      opacity: 1 !important;
      pointer-events: auto !important;
      transition: 0.3s;
      margin-right: 10px;
      display: block !important;

      &:hover {
        transform: scale(0.9);
      }
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .menu__item {
    position: relative;
    .menu__link {
      padding: 0 ac(24px, 14px);
      height: ac(64px, 55px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.menu-dropdown {
      padding-right: 12px;
      position: relative;

      @media (max-width: 640px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }

      @media (max-width: 1200px) {
        padding-right: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        .menu__link {
          white-space: nowrap;
        }

        .menu-dropdown__list {
          width: 100% !important;
        }

        @media (max-width: 640px) {
          justify-content: center;
        }
      }

      /*.menu-dropdown {
        &__arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--cl-beige);
          cursor: pointer;
          transition: all .3s ease;

          @media (min-width: 1201px) {
            position: absolute;
            top: 55%;
            transform: translateY(-50%) scale(1, 1);
            right: 0;
            font-size: 7px;
          }

          @media (max-width: 1200px) {
            transform: scale(1, 1);
            font-size: 12px;
            margin-left: 8px;

            &.active {
              transform: scale(1, -1);
            }
          }
        }

        &__list {
          transition: max-height .3s ease, opacity .3s ease, margin-top .3s ease;
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          width: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          @media (min-width: 1201px) {
            padding-left: 10px;
            padding-right: 10px;
            position: absolute;
            bottom: -2px;
            transform: translateY(100%);
            background-color: rgba(28, 28, 28, 0.8);
            z-index: 999;

            .menu__link {
              &:first-child {
                margin-top: 5px !important;
              }

              &:last-child {
                margin-bottom: 5px !important;
              }
            }
          }

          .menu__link {
            white-space: nowrap;
          }

          @media (max-width: 640px) {
            align-items: center;
          }

          @media (max-width: 1200px) {
            &.active {
              max-height: 300px;
              opacity: 1;
              margin-top: 25px;
              grid-row-gap: 25px;
            }
          }
        }
      }*/

      /*&:hover {
        !*.menu-dropdown__arrow {
          color: var(--cl-mint);
        }

        @media (min-width: 1201px) {

          .menu-dropdown__arrow {
            transform: translateY(-50%) scale(1, -1);
          }

          .menu-dropdown__list {
            max-height: 300px;
            opacity: 1;
          }
        }*!
      }*/
    }
  }

  .menu__link {
    position: relative;
    font-size: ac(18px, 15px);
    line-height: normal;
    font-weight: 500;
    color: var(--cl-white);
    transition: all 0.3s ease;
    cursor: pointer;
    font-family: var(--font-main);

    /*&:before {
      right: 0;
      left: auto;
      bottom: 0;
      width: 0;
      height: 1px;
      background: var(--cl-mint);
      opacity: 0;
      transition: width .3s ease, opacity .3s ease;
      content: "";
      position: absolute;
    }

    &:hover {
      color: var(--cl-mint);

      &:before {
        right: auto;
        left: 0;
        width: 100%;
        opacity: 1;
      }
    }*/
  }

  &__buttons {
    &.buttons {
      padding-left: ac(20px, 16px);

      @media (max-width: 640px) {
        margin-top: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .btn {
        height: 52px;
        border-color: rgba(225, 232, 255, 0.4);
        min-width: ac(154px, 140px);

        &__text {
          font-size: ac(16px, 18px);
          white-space: nowrap;
        }
      }

      @media (max-width: 1200px) {
        display: none;
      }

      @media (max-width: 640px) {
        display: flex;
      }

      @mixin media 551 {
        .btn {
          width: 100%;
          height: 54px;
        }
      }
    }

    &--pad {
      display: none;

      @media (max-width: 1200px) {
        @media (min-width: 641px) {
          display: flex !important;
          padding-right: 20px;

          .btn {
            height: ac(52px, 48px);
          }
        }
      }

      @media (max-width: 640px) {
        display: none !important;
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media (max-width: 1200px) {
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
    }

    .logo {
      border: none;
    }

    .header__navbar-cont {
      display: flex;
      flex-direction: column;

      height: 100%;
      margin: 0 auto;
      max-width: 1280px;
      width: perc(1280);

      @media (max-width: 1024px) {
        width: 89.33%;
      }
    }

    .header__navbar {
      position: fixed;
      right: 0;
      top: 0;
      width: 40%;
      min-width: 320px;
      padding-top: ac(180px, 130px);
      background: var(--cl-black);
      padding-right: 40px;
      padding-left: 40px;
      padding-bottom: 40px;
      transition: transform 0.3s ease-out;
      overflow: hidden;
      flex-direction: column;
      height: auto;
      border-bottom-left-radius: 50px;

      @media screen and (max-height: 740px) {
        padding-top: 100px;
      }

      @media screen and (max-height: 660px) {
        padding-top: 90px;
      }

      @media (max-width: 768px) {
        padding-right: calc((100vw - 89.33vw) / 2);
        padding-left: calc((100vw - 89.33vw) / 2);
      }

      @media (max-width: 640px) {
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        border-bottom-left-radius: 0;
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      .header__socials {
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 100%;
        position: relative;
        z-index: 1;
        grid-row-gap: 8px;

        /*@media (max-width: 640px) {
          justify-content: flex-start;
          padding-left: 4px;
        }*/
      }

      .header__social-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-column-gap: 7px;

        i {
          font-size: 24px;
          transition: color 0.3s ease;
          color: var(--cl-white);
        }

        span {
          font-size: 16px;
          font-weight: 700;
          transition: color 0.3s ease;
          letter-spacing: 0.02em;
          color: var(--cl-white);
        }

        &:hover {
          span,
          i {
            color: var(--cl-primary);
          }
        }
      }

      .header__contact-link {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.02em;
        display: flex;
        transition: color 0.3s ease;
        color: var(--cl-white);

        &:hover {
          color: var(--cl-primary);
        }
      }

      .menu {
        margin-top: auto;
        max-height: 100%;
        margin-bottom: auto;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0;

        align-items: flex-start;
        align-self: center;
        position: relative;
        z-index: 2;

        overflow-x: hidden;
        overflow-y: auto;
        clip-path: inset(0 0 0 0 round 30px);
        padding-right: 4px;
        width: calc(100% + 4px);

        .simplebar-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;

          .menu-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        .simplebar-track.simplebar-vertical {
          width: 4px;
          background: var(--cl-black);
          border-radius: 0;
          transform: none;

          .simplebar-scrollbar {
            background: var(--cl-grey);
            border-radius: 0;

            &:before {
              content: none;
            }
          }
        }

        &__list {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          border-radius: 30px;
          overflow: hidden;
          background: var(--cl-blue);
          padding: 0;
        }

        &__item {
          display: flex;
          justify-content: stretch;
          align-items: stretch;
          width: 100%;
          background: transparent;

          &.active {
            .menu__link {
              border-color: var(--cl-pink);
            }
          }

          &:hover {
            &:not(.active) {
              .menu__link {
                border-color: var(--cl-pink);
              }
            }
          }
        }

        &__link {
          font-family: var(--font-main);
          font-size: 20px;
          line-height: normal;
          text-align: center;
          border-radius: 32px;
          min-height: 64px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: border-color 0.3s ease, color 0.3s ease;
          border: 4px solid transparent;

          /*&:hover {
            &:not(.active) {
              border-color: var(--cl-pink);
              !*color: var(--cl-lilac);*!
            }
          }*/

          @media screen and (max-height: 745px) {
            font-size: 18px;
            min-height: 56px;
          }
        }

        /*&__item.hidden {
          display: block;

          &.first-hidden {
            margin-top: 28px;

            @media screen and (max-height: 730px) {
              margin-top: 10px;
            }

            @media screen and (max-height: 640px) {
              margin-top: 0;
            }
          }

          .menu__link {
            position: relative;

            &:before {
              left: 0;
              bottom: 0;
              right: 0;
              height: 1px;
              width: 100%;
              opacity: 1;
              background: var(--cl-leaf);
              content: "";
              position: absolute;
              display: block;
            }
          }
        }*/
      }

      .header__buttons {
        padding-left: 0;
      }
    }

    .header__navbar-decor {
      position: absolute;
      inset: -1px;
      display: block;
      opacity: 0.7;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    /*&.active {
      padding: 14px *;
      .logo {
        width: 215px;
      }
    }*/
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  background: var(--cl-white);
  position: relative;
  z-index: 100;
  clip-path: inset(0 0 0 0 round 50%);
  overflow: hidden;
  transition: background-color 0.3s ease;
  width: ac(55px, 48px);
  height: ac(55px, 48px);
  display: flex;
  justify-content: center;
  align-items: center;

  /*&:before {
    z-index: 1;
    inset: 0;
    content: '';
    position: absolute;
    background: var(--gradient);
    transition: opacity 0.3s ease;
    opacity: 0;
  }*/

  .burger-icon {
    cursor: pointer;
    transition: transform 400ms, background-color 0.3s ease;
    user-select: none;
    width: 43px !important;
    height: 43px !important;
    position: relative;
    z-index: 2;
    transform: scaleX(1.2);
  }

  &.active {
    /*&:before {
      opacity: 1;
    }*/

    .burger-icon {
      transform: rotate(45deg) scale(1.4);
    }

    .top {
      stroke-dashoffset: -68px;
    }

    .bottom {
      stroke-dashoffset: -68px;
    }

    .line {
      stroke: var(--cl-primary);
    }
  }

  .line {
    fill: none;
    transition: stroke 0.3s ease, stroke-dasharray 400ms,
      stroke-dashoffset 400ms, opacity 0.3s ease;
    stroke: var(--cl-primary);
    stroke-width: 3.5;
    stroke-linecap: round;
  }

  .top {
    stroke-dasharray: 40 121;
  }

  .bottom {
    stroke-dasharray: 40 121;
  }

  &:hover {
    .line {
      stroke: var(--cl-black);
    }
  }

  &.active {
    &:hover {
      .line {
        stroke: var(--cl-black);
      }
    }
  }

  @media (max-width: 360px) {
    transform: scale(1.01);
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}
/* end of HEADER COMPONENTS */
