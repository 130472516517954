.ethos-section {
  background: var(--cl-white);
  overflow: hidden;
  z-index: 10;

  &__wrapper {
    padding-top: 20px;
    padding-bottom: ac(120px, 40px);
  }

  &__slider {
    .swiper {
      overflow: visible;

      &:not(.swiper-initialized) {
        .swiper-wrapper {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          grid-column-gap: ac(20px, 16px);
          grid-row-gap: ac(20px, 16px);

          @media (max-width: 1100px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        + .swiper-buttons {
          display: none;
        }
      }

      + .swiper-buttons {
        .swiper-button {
          margin-top: ac(32px, 24px);
        }
      }

      @media (max-width: 500px) {
        max-width: 320px;
        margin-left: 0;
      }
    }
  }
}
