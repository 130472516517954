.hire-section {
  padding-top: ac(80px, 30px);
  padding-bottom: ac(120px, 78px);

  position: relative;

  z-index: 1;
  background: var(--cl-blue-grey);

  .line-decor {
    top: -20px;
    bottom: auto;

    &--2 {
      bottom: -15px;
      top: auto;
      z-index: 5;
    }

    &--3 {
      bottom: -25px;
      top: auto;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    gap: ac(64px, 32px);
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__bg {
    position: absolute;
    height: clamp(320px, 31.25vw + 80px, 580px);
    top: 0;
    left: 0;
    right: 0;
  }

  &__title {
    color: var(--cl-white);

    span {
      color: var(--cl-pink);
    }
  }

  &-card {
    display: flex;
    flex-direction: column;
    gap: ac(20px, 14px);

    width: 100%;
    height: auto;

    background: var(--cl-white);
    border: 4px solid #edf0f8;
    border-radius: ac(24px, 18px);

    padding: ac(32px, 24px) ac(35px, 20px) ac(60px, 30px);

    &__wrap {
      display: flex;
      align-items: center;

      gap: ac(16px, 12px);
    }

    &__title {
      padding-bottom: ac(16px, 12px);

      font-family: var(--font-second);

      color: var(--cl-primary);
      font-weight: 700;
      font-size: ac(30px, 22px);
      line-height: normal;

      @media (max-width: 360px) {
        font-size: 20px;
      }
    }

    &__subtitle {
      max-height: calc(7 * 1.6em);
      overflow: auto;
      scrollbar-width: thin;
      @media (max-width: 640px) {
        max-height: calc(9 * 1.6em);
      }
    }

    &__step {
      color: var(--cl-primary);
      font-weight: 500;
    }

    &__num {
      padding: ac(4px, 2px) 23px;
      background: var(--cl-blue-grey);

      color: var(--cl-pink);

      border-radius: 1000px;
    }
  }

  &__slider {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .swiper {
      overflow: visible;
      width: 100%;

      max-width: ac(1064px, 900px);
      margin-left: 0;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;

    .swiper-button {
      border: 2px solid #ffffff66;
      border-radius: 50%;
      --size: ac(62px, 55px);
      width: var(--size);
      height: var(--size);
      background: transparent;

      &:before {
        color: var(--cl-white);
      }
    }

    @media (min-width: 641px) {
      position: absolute;
      top: ac(7px, 0);
      right: 0;
    }

    @media (max-width: 640px) {
      .swiper-button {
        border: 2px solid var(--cl-navy);
        border-radius: 50%;
        --size: ac(62px, 55px);
        width: var(--size);
        height: var(--size);
        background: transparent;

        &:before {
          color: var(--cl-navy);
        }

        &:hover {
          &:before {
            color: var(--cl-white);
          }
        }
      }

      .swiper-button {
        margin-top: 32px;
      }
    }
  }
}
