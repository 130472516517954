.talent-section {
  background: var(--cl-blue-grey);
  padding-top: ac(80px, 40px);
  padding-bottom: ac(120px, 60px);

  .line-decor {
    top: ac(-30px, -35px);

    @media (min-width: 1650px) {
      top: -80px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: ac(64px, 32px);
  }

  &__top {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    @media (max-width: 768px) {
      flex-direction: column;
      grid-row-gap: 20px;
    }
  }

  &__bottom {
    position: relative;
    padding-top: 30px;
    padding-bottom: 41px;

    .line-decor {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    @media (max-width: 640px) {
      padding-bottom: 0;
    }
  }

  &__title {
    width: 50%;

    h2 {
      color: var(--cl-primary);
    }

    span {
      color: var(--cl-pink);
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__subtitle {
    width: 42.33%;
    line-height: normal;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    overflow: visible;
    position: relative;

    .swiper {
      width: 100%;
      max-width: ac(574px, 390px);
      overflow: visible;

      @media (max-width: 961px) {
        max-width: 520px;
      }
    }

    .swiper-buttons {
      @media (min-width: 962px) {
        display: none;
      }

      .swiper-button {
        margin-top: ac(32px, 24px);
      }
    }

    &:after {
      position: absolute;
      top: 2.5px;
      bottom: 0;
      left: 0;
      width: 100vw;
      transform: translateX(-100%);
      content: '';
      border-top: 5px solid var(--cl-pink);
      border-bottom: 5px solid var(--cl-pink);
      opacity: 0;
      pointer-events: none;
      background: var(--cl-blue-grey);
    }

    &:before {
      position: absolute;
      top: 2.5px;
      bottom: 0;
      right: 0;
      width: 100vw;
      transform: translateX(100%);
      content: '';
      border-top: 5px solid var(--cl-pink);
      border-bottom: 5px solid var(--cl-pink);
      opacity: 0;
      pointer-events: none;
      background: var(--cl-blue-grey);
    }

    @media (min-width: 1450px) {
      &:after,
      &:before {
        opacity: 1;
        z-index: 10;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 146px;
    padding-right: 146px;
    padding-top: 30px;
    padding-bottom: 41px;
    height: auto;
    transition: padding 0.3s ease;
    position: relative;

    &-img {
      aspect-ratio: 1 / 1;
      position: relative;
      z-index: 5;
    }

    &-title {
      color: var(--cl-primary);
      font-weight: 700;
      font-size: ac(28px, 22px);
      line-height: 35.84px;
      letter-spacing: 1.5px;
      padding-top: ac(8px, 6px);
      position: relative;
      z-index: 5;
    }

    &-subtitle {
      padding-top: ac(12px, 10px);
      font-weight: 600;
      font-size: ac(16px, 14px);
      line-height: 23.68px;
      letter-spacing: 0.1px;
      position: relative;
      z-index: 5;
    }

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -4px;
      right: -4px;
      content: '';
      z-index: 1;
      clip-path: url('#talent-clip-path');
      background: var(--cl-pink);
    }

    &:before {
      position: absolute;
      top: 5px;
      bottom: 5px;
      left: 2px;
      right: 2px;
      content: '';
      z-index: 2;
      clip-path: url('#talent-clip-path');
      background: var(--cl-blue-grey);
    }

    &.swiper-slide-prev {
      @media (min-width: 962px) {
        padding-right: ac(118px, 70px);
        padding-left: ac(216px, 250px);
      }
    }

    &.swiper-slide-next {
      @media (min-width: 962px) {
        padding-left: ac(118px, 70px);
        padding-right: ac(216px, 250px);
      }
    }

    @media (max-width: 551px) {
      padding-left: 70px;
      padding-right: 70px;
      padding-bottom: 50px;
    }
  }
}
