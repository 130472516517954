.why-section {
  padding-top: ac(80px, 40px);
  padding-bottom: ac(60px, 30px);

  z-index: 1;
  overflow: hidden;

  background: var(--cl-blue-grey);

  .cont {
    position: relative;
  }

  @media (min-width: 1440px) {
    padding-bottom: 60px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: 840px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__image {
    max-width: ac(638px, 430px, 840);
    width: 100%;
    background-color: #0b2fa0;
    border-radius: ac(32px, 20px);
    z-index: 0;
    overflow: hidden;
    position: relative;

    height: 641px;

    @media (max-width: 840px) {
      max-width: none;
      max-height: ac(360px, 180px, 320, 840);
    }

    img {
      @media (max-width: 840px) {
        object-position: ac(0, -120px, 320, 840) ac(-140px, -230px, 320, 840);
        height: auto;
        width: auto;
        object-fit: cover;
      }
      @media (max-width: 500px) {
        object-position: ac(-30px, -20px, 320, 500) ac(-190px, -110px, 320, 500);
      }
    }
  }

  &__title {
    color: var(--cl-primary);

    padding-bottom: ac(24px, 14px);

    span {
      color: var(--cl-pink);
    }
  }

  &__subtitle {
    padding-bottom: ac(16px, 8px);

    color: var(--cl-primary);
  }

  &__text {
    padding-bottom: ac(32px, 16px);
  }

  &__card {
    background-color: var(--cl-white);
    max-width: ac(738px, 580px, 840);
    width: 100%;
    border-radius: ac(32px, 20px);
    padding: ac(64px, 22px);
    padding-bottom: ac(56px, 20px);
    position: absolute;
    right: 0;
    z-index: 2;
    @media (max-width: 840px) {
      position: relative;
      right: auto;
      max-width: none;
    }
  }

  &.areas-page {
    overflow: visible;
    z-index: 10;

    &.why-section {
      padding-top: ac(80px, 40px);
      padding-bottom: ac(80px, 40px);
      background: transparent;
    }

    .why-section__card {
      background: var(--cl-blue-grey);
    }
    .why-section__wrapper {
      margin-bottom: ac(18px, 0px);
    }
  }

  &.team-page {
    z-index: 0;

    &.why-section {
      padding-bottom: ac(120px, 80px);
    }
  }
}
