@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,700;1,6..12,700&display=swap');

:root {
  --cl-black: #0a0a0a;
  --cl-black-rgb: 0 0 0;

  --cl-primary: #002495;
  --cl-primary-rgb: 236 97 42;

  --cl-navy: #010f93;

  --cl-white: #ffffff;
  --cl-white-rgb: 255 255 255;

  --cl-grey: #e0e4e1;

  --cl-blue: #002495;

  --cl-pink: #e6007e;

  --cl-blue-grey: #edf0f8;

  --gradient: linear-gradient(90deg, #000000 0%, #2800d7 100%);

  --font-main: 'Red Hat Text', sans-serif;
  --font-second: 'Gellix', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;

  --vh: calc(100vh / 100);
  --vh: calc(100vh / 100);

  --arw: 1;
  --arh: 1;
  --max-line-num: 3;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (); */
