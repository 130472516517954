.our-vision-section {
  padding-top: ac(140px, 76px);
  padding-bottom: ac(70px, 30px);
  position: relative;
  overflow: visible;

  &__bg {
    @mixin aspect-ratio 3100, 1528;
    width: ac(3100px, 1700px);
    position: absolute;
    z-index: 1;
    top: ac(-201px, -150px);
    left: 40%;
    transform: translateX(-50%);
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: ac(50px, 24px);
    position: relative;
    z-index: 2;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      opacity: 0;
      width: 1px;
      background: var(--cl-primary);
      transition: height 0.4s ease, opacity 0.5s ease;
      content: '';
    }

    &[data-view] {
      &.animated {
        &:before {
          height: 100%;
          opacity: 1;
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(40px, 20px);

    li {
      font-size: ac(60px, 30px);
      line-height: 1.12;
      font-weight: 300;
      color: var(--cl-primary);
    }

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__buttons {
    @mixin full-btn;
  }
}
