.cookie-terms-section {
  background: var(--cl-blue-grey);
  padding-top: ac(120px, 60px);
  padding-bottom: ac(120px, 60px);

  p {
    font-size: ac(20px, 16px);
  }

  strong {
    color: var(--cl-primary);
  }

  ul {
    list-style-type: none !important;
    padding-left: 0;
    li {
      position: relative;
      padding-left: ac(24px, 14px);
      &:not(:last-child) {
        margin-bottom: ac(12px, 10px);
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: ac(12px, 8px);
        --size: 8px;
        height: var(--size);
        width: var(--size);
        min-width: var(--size);
        border-radius: 100%;
        background-color: var(--cl-pink);
      }
    }
  }

  a {
    color: var(--cl-primary);
    font-size: ac(20px, 16px);
    position: relative;
    width: fit-content;
    text-decoration-color: transparent;
    transition: 0.3s all;
    font-weight: 600!important;
    display: inline;
    &:hover {
      text-decoration-color: var(--cl-blue) !important;
    }
  }

  h1 {
    font-size: ac(40px, 32px);
    color: var(--cl-primary);
    font-weight: 700;
  }

  h2 {
    font-size: ac(32px, 24px);
    color: var(--cl-primary);
    font-weight: 600;
    margin-top: ac(40px, 20px);
  }

  h3 {
    font-size: ac(28px, 22px);
    color: var(--cl-primary);
    font-weight: 500;
    &:nth-of-type(2) {
      margin-top: 40px;
    }

    &:nth-of-type(4) {
      margin-top: 40px;
    }
  }

  h4 {
    font-size: ac(26px, 20px);
    color: var(--cl-primary);
    font-weight: 500;
    margin-top: ac(24px, 18px);
  }

  h5 {
    font-size: ac(25px, 20px);
    color: var(--cl-primary);
    font-weight: 500;
    margin-top: ac(24px, 18px);
  }

  h6 {
    font-size: ac(24px, 20px);
    color: var(--cl-primary);
    font-weight: 500;
    margin-top: ac(24px, 18px);
  }

  .text-red-400 {
    color: var(--cl-primary);
  }
}

.single-page-404 {
  position: relative;
  background: var(--cl-blue-grey);
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 5;
  padding-left: 20px;
  padding-right: 20px;

  h1 {
    color: var(--cl-primary);
    font-size: ac(90px, 60px);
    text-align: center;

    @media (max-width: 450px) {
      font-size: 50px;
    }

    @media (max-width: 360px) {
      font-size: 40px;
    }
  }

  p {
    font-size: ac(22px, 20px);
    text-align: center;
  }

  a {
    margin-top: 32px;
  }

  .line-decor {
    position: absolute;
    z-index: 15;
  }
}
