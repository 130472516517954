.history-section {
  margin-top: 30px;
  padding-top: ac(102px, 30px);
  padding-bottom: ac(102px, 60px);

  @media (min-width: 1600px) {
    .line-decor {
      &:nth-child(1) {
        top: -80px;
      }

      &:nth-child(2) {
        top: -60px;
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 120px;
    }
  }

  &__title {
    color: var(--cl-blue);
    &:not(:last-child) {
      margin-bottom: ac(48px, 24px);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 49.22%;
    max-width: 49.22%;

    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    clip-path: inset(ac(-38px, -22px) 0 -200px 0);
    z-index: 1;
    position: relative;

    &:after {
      position: absolute;
      bottom: -200px;
      left: -1px;
      right: -1px;
      z-index: 1;
      pointer-events: none;
      height: 200px;
      content: '';
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 10%,
        rgba(255, 255, 255, 1) 55%,
        rgba(255, 255, 255, 1) 100%
      );

      @media (max-width: 768px) {
        bottom: -120px;
        height: 120px;
      }
    }

    .swiper {
      max-height: 100%;
      overflow: visible;
    }

    .swiper-buttons {
      display: none;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: ac(76px, 60px);
    height: auto;
    padding-bottom: ac(40px, 24px);
    transition: 0.3s;
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 24px;
      width: 4px;
      background: var(--cl-blue-grey);
      z-index: -1;
      content: '';
    }

    &.swiper-button-prev {
      opacity: 0;
    }

    &:not(.swiper-slide-active) {
      .history-section__item-title {
        color: var(--cl-white);
        text-shadow: -1px -1px 0 var(--cl-pink), 1px -1px 0 var(--cl-pink),
          -1px 1px 0 var(--cl-pink), 1px 1px 0 var(--cl-pink);
      }
    }

    &.swiper-slide-active {
      .history-section__item-circle {
        &:before {
          background-color: var(--cl-pink);
        }

        &:after {
          opacity: 1;
          transform: translateX(-50%) translateY(-50%) scale(1);
        }
      }
    }

    &:first-child {
      &:after {
        top: 60px;
      }
    }

    &:last-child {
      &:after {
        bottom: auto;
        height: 60px;
      }
    }
  }

  &__item-circle {
    --size: 28px;
    width: var(--size);
    height: var(--size);

    position: absolute;
    left: 12px;
    top: 43px;
    z-index: 5;
    transition: 0.3s;

    &:after {
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      background: var(--cl-white);
      border: 4px solid var(--cl-blue-grey);
      opacity: 0;
      top: 50%;
      left: 50%;
      --size: 52px;
      width: var(--size);
      height: var(--size);
      transform: translateX(-50%) translateY(-50%) scale(0);
      content: '';
      transition: 0.3s;
    }

    &:before {
      border-radius: 50%;
      background: var(--cl-blue-grey);
      --size: 28px;
      width: var(--size);
      height: var(--size);
      transition: 0.3s;
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 2;
    }
  }

  &__item-title {
    color: var(--cl-pink);
    transition: 0.3s;

    &.heading-1 {
      line-height: 1.2;
    }

    &:not(:last-child) {
      margin-bottom: ac(8px, 14px);
    }
  }

  &__item-text {
    color: var(--cl-black);
  }

  &__images-container {
    position: relative;
    width: 46.41%;
    z-index: 10;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__image-bg {
    position: absolute;
    left: ac(181px, 64px);
    top: ac(-102px, -60px);
    bottom: ac(-102px, -60px);
    right: ac(-80px, -40px);
    overflow: hidden;
    border-radius: ac(40px, 32px) 0 0 ac(40px, 32px);

    @media (min-width: 1441px) {
      right: calc(((100vw - 1280px) / 2) * -1);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__image {
    --arw: 594;
    --arh: 475;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    border-radius: ac(32px, 24px);
    border: 1px solid var(--cl-blue-grey);
    overflow: hidden;
    transition: opacity 0.3s;

    &.active {
      position: relative;
      left: auto;
      right: auto;
      opacity: 1;
    }
  }
}
