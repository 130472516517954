.meta-input,
.meta-select,
.meta-upload-file {
  label,
  .label {
    font-family: var(--font-main);
    font-size: 17px;
    line-height: normal;
    font-weight: 500;
    color: var(--cl-black);
    padding-bottom: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 4px;
      display: inline-block;
      color: var(--cl-primary);
    }
  }
}

.dark-bg {
  .meta-input,
  .meta-select,
  .meta-upload-file {
    label,
    .label {
      color: var(--cl-white);
    }
  }
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  input,
  textarea {
    width: 100%;
    font-size: ac(18px, 16px);
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    color: var(--cl-white);
    border-bottom: 1px solid var(--cl-white);
    border-radius: 0;
    outline: none;
    transition: box-shadow 0.25s ease, border-color 0.3s ease,
      caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    padding: 16px 16px ac(18px, 16px);
    height: ac(62px, 48px);
    background: transparent;
    @mixin transparent-bg-input var(--cl-white);

    &::placeholder {
      transition: all 0.3s ease;
      color: var(--cl-blue-grey);
    }

    &:focus {
      /* border-color: var(--cl-primary); */
      caret-color: var(--cl-white);
      /* box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3); */
    }

    /*&.error {
      border-color: var(--cl-red);
      box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
    }*/
  }

  textarea {
    padding-top: 16px;
    min-height: ac(78px, 90px);
    max-height: ac(78px, 90px);
    overflow-y: auto;
    resize: none;
    clip-path: inset(-1px -1px -1px -1px round ac(39px, 28px));
    border-radius: ac(39px, 28px);

    scrollbar-width: thin;
    scrollbar-color: var(--cl-primary) transparent;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-primary);
      border-radius: 2px;
    }
  }

  &.dark-border {
  }

  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 700;
  }

  &__help {
    color: var(--cl-white);
  }

  /*&__error {
    color: var(--cl-red);
  }*/

  &.form-type {
    input {
      @mixin transparent-bg-input var(--cl-black);
    }
  }
}

.dark-bg {
  .meta-input {
    input,
    textarea {
      border-color: var(--cl-white);
      color: var(--cl-white);
      background: transparent;
      @mixin transparent-bg-input var(--cl-white);

      &::placeholder {
        color: var(--cl-white);
        opacity: 0.5;
      }

      &:focus {
        border-color: var(--cl-primary);
        caret-color: var(--cl-white);
        box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
      }

      &:focus {
        border-color: var(--cl-primary);
      }
    }
  }
}

.is-safari {
  .meta-input {
    textarea {
      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-track {
        background: initial;
        border-radius: initial;
      }

      &::-webkit-scrollbar-thumb {
        background: initial;
        border-radius: initial;
      }
    }
  }
}

.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  /*label,
  .label {
    font-family: var(--font-main);
    color: var(--cl-leaf);
    font-size: ac(14px, 12px);
    font-weight: 700;
    padding-bottom: ac(13px, 6px);
    cursor: pointer;
  }*/

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(17px, 10px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(13px, 10px);
    }
  }

  &__input {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(17px, 16px);
    line-height: normal;
    font-weight: 900;
    font-family: var(--font-main);
    color: var(--cl-primary);
    border: 2px solid var(--cl-primary);
    border-radius: 28px;
    background: var(--cl-blue-grey);
    transition: all 0.3s ease;
    padding: 4px ac(28px, 18px);
    cursor: pointer;
    position: relative;
    height: ac(44px, 40px);
    text-transform: uppercase;

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
      color: var(--cl-primary);
    }

    &:hover {
      background-color: transparent;
      color: var(--cl-pink);
    }
  }

  &__file-name {
    max-width: calc(100% - ac(122px, 114px));
    @mixin max-line-length-one;
    font-size: ac(18px, 15px);
    font-weight: 300;
    color: var(--cl-primary);
  }

  &__info {
    margin-top: 10px;
    font-size: ac(14px, 12px);
    line-height: normal;
    font-weight: 300;
    font-family: var(--font-second);
    font-style: italic;
    color: var(--cl-black);
  }

  &.drag {
    .meta-upload-file__input {
      background-color: var(--cl-primary);
      box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-primary) / 0.3);
    }
  }

  &.drop {
    .meta-upload-file__input {
      border-style: solid;
    }

    &:not(.done) {
      .meta-upload-file__input {
        transform: scale(0.9);
      }
    }
  }
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &__box {
    --size: ac(25px, 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    min-width: var(--size);
    height: var(--size);
    border: 1px solid var(--cl-black);
    margin-right: ac(10px, 8px);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background: transparent;

    &:hover {
      border-color: var(--cl-primary);
    }

    .check-clip,
    .circle-clip {
      width: 85%;
      height: 85%;
      background-color: var(--cl-black);
      opacity: 0;
      transition: opacity 0.3s ease, background-color 0.3s ease;
    }

    .check-clip {
      clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
    }

    .circle-clip {
      clip-path: circle(50%);
    }

    i {
      opacity: 0;
      font-size: ac(12px, 10px);
      color: var(--cl-white);
      transition: opacity 0.3s ease, color 0.3s ease;
    }
  }

  input {
    display: none;
  }

  input[type='checkbox'] {
    + .meta-checkbox__box {
      border-radius: 2px;
    }
  }

  input[type='radio'] {
    + .meta-checkbox__box {
      border-radius: 50%;
    }
  }

  &__text {
    font-size: ac(20px, 18px);
    font-weight: 400;
    line-height: 1.4;
    font-family: var(--font-main);
    color: var(--cl-charcoal);

    a {
      display: inline;
      font: inherit;
      color: var(--cl-primary);
      text-decoration: underline;
      text-decoration-color: var(--cl-primary);
      transition: all 0.25s ease;

      &:hover {
        color: var(--cl-pink);
        text-decoration-color: var(--cl-pink);
      }
    }
  }

  input:checked ~ .meta-checkbox__box {
    border-color: var(--cl-pink);
    background: var(--cl-pink);
    .check-clip,
    .circle-clip,
    i {
      opacity: 1;
      color: var(--cl-white);
    }

    &:hover {
      border-color: var(--cl-charcoal);
    }
  }
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  select {
    opacity: 0;
    height: ac(62px, 49px);
  }

  .choices {
    width: 100%;
    margin-bottom: 0;
    overflow: visible;

    &:not(.is-open) {
      overflow: hidden;
    }

    &__inner {
      transition: box-shadow 0.3s ease, border-color 0.3s ease,
        border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
      padding: 18px 16px !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @mixin max-line-length-one;
      width: 100%;
      height: ac(62px, 49px);
      background: transparent;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-family: var(--font-main);
      color: var(--cl-blue-grey);
      border: none;
      border-bottom: 1px solid var(--cl-white);
      border-radius: 0 !important;
      outline: none;

      /*&:hover {
        border-color: var(--cl-orange);
      }*/
      /*&.error {
        border-color: var(--cl-red);
        box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
      }*/
    }
    &.is-open {
      &:not(.is-flipped) {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-top: 8px;
        }
      }

      &.is-flipped {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-bottom: 8px;
        }
      }
    }

    &__item {
      @mixin max-line-length-one;
    }

    &__placeholder {
      color: var(--cl-black);
      opacity: 1;
      background: transparent;
    }

    &__list {
      padding: 0;
      background-color: var(--cl-white);

      .choices__item {
        padding-right: ac(36px, 30px);
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-family: var(--font-second);
        color: var(--cl-white);
        transition: all 0.3s ease;
        text-decoration: underline;
        text-decoration-color: transparent;

        &.is-selected {
          color: var(--cl-black);
        }
      }

      &--single {
        background-color: transparent !important;

        .choices__item {
          color: var(--cl-blue-grey);
        }
      }

      &--multiple {
        color: var(--cl-black);
        .choices__item--selectable {
          display: none;
        }

        + .choices__input {
          display: none;
        }
      }

      &--dropdown {
        .choices__item {
          color: rgba(0, 0, 0, 0.617);
          text-decoration: none !important;

          &:hover {
            &:not(.is-selected) {
              color: var(--cl-black) !important;
              text-decoration: none !important;
            }
          }
        }
      }
    }

    &[data-type*='select-one'],
    &[data-type*='select-multiple'] {
      cursor: pointer;
      &:after {
        border: none;
        content: '\e900';
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        transition: all 0.15s ease-in-out;
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        width: ac(56px, 50px);
        height: ac(56px, 50px);
        margin-top: 0;
        z-index: 22;
        color: #fff;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &:before {
        position: absolute;
        width: ac(24px, 20px);
        height: ac(24px, 50px);
        border: none;
        border-radius: 50%;
        background: transparent;
        content: '';
        z-index: 1;
        top: 0;
        bottom: 0;
        right: 0;
      }
    }

    &.is-open {
      &:after {
        transform: scaleY(-1);
      }
    }

    &__list--dropdown .choices__item--selectable.is-highlighted,
    &__list[aria-expanded] .choices__item--selectable.is-highlighted {
      background-color: transparent;

      &:not(.is-selected) {
        color: var(--cl-black);
      }
    }

    &__list--dropdown,
    .choices__list[aria-expanded] {
      /* border: 1px solid var(--cl-black); */
      z-index: 22;
      border-radius: 0;
    }

    &[data-type*='select-one'] .choices__input {
      margin: 2px;
      max-width: calc(100% - 4px);
      border: 1px solid var(--cl-primary-rgb);
      border-radius: 0;
      background: var(--cl-white);
      color: var(--cl-black);
      font-size: ac(16px, 14px);
      line-height: 1.2;
      font-weight: 500;
      font-family: var(--font-main);
    }

    &[data-type*='select-multiple'] .choices__inner {
      cursor: pointer;
    }
  }
}
