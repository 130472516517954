.text-section {
  padding-top: ac(70px, 30px);
  padding-bottom: ac(45px, 30px);
  position: relative;
  z-index: 10;
  overflow: hidden;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(90px, 30px);
    }
  }

  &__text {
    width: 100%;
  }
}
