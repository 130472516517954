.who-we-are-section {
  background: var(--cl-blue-grey);
  z-index: 10;
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: 1700px) {
    padding-bottom: 60px;
  }

  .line-decor {
    bottom: ac(20px, 30px);
  }

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 47%;
    background: var(--cl-white);
    z-index: 1;
    content: '';
  }

  &__wrap {
    position: relative;
    z-index: 2;
  }

  &__container {
    position: relative;
    padding: ac(120px, 64px) ac(64px, 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--cl-white);
    z-index: 1;

    &.join-us {
      align-items: flex-start;
    }
  }

  &__image {
    position: absolute;
    z-index: -1;
    inset: 0;
    border-radius: ac(24px, 20px);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    width: 41.93%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 55.3%;
  }

  &__text {
    font-size: ac(22px, 14px);
    line-height: 32.56px;

    &.join-us {
      font-size: ac(22px, 14px);
    }
  }

  @media (max-width: 768px) {
    .who-we-are-section {
      &__container {
        flex-direction: column;
        align-items: flex-start;
      }

      &__title {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }

      &__content {
        width: 100%;
      }
    }
  }

  &.areas-type {
    padding-top: ac(40px, 20px);

    &:before {
      top: auto;
      bottom: 0;
      height: 120px;
      background: var(--cl-white);
    }

    .who-we-are-section {
      &__image {
        &:after {
          position: absolute;
          inset: 0;
          background: var(--cl-blue);
          mix-blend-mode: color;
          content: '';
          border-radius: 24px;
          filter: blur(10px);
        }

        img {
          filter: blur(10px);
        }
      }
    }

    @media (min-width: 769px) {
      .who-we-are-section {
        &__title {
          width: 60%;
        }

        &__content {
          width: 36%;
        }

        &__buttons {
          width: 100%;
          justify-content: flex-end;
        }
      }
    }

    @media (max-width: 551px) {
      .who-we-are-section {
        &__title {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  + .text-image-section {
    padding-top: 24px;
  }

  &.about-page {
    background: var(--cl-white);

    &.who-we-are-section {
      padding-top: ac(67.5px, 30px);
      padding-bottom: 10px;
    }

    &:before {
      background: var(--cl-blue-grey);
    }
  }

  &.about-type {
    &:before {
      background: var(--cl-white);
    }

    .line-decor {
      top: 40px;
      bottom: auto;

      @media (max-width: 551px) {
        top: -15px;
      }
    }

    @media (max-width: 551px) {
      overflow: visible;
      z-index: 10;
    }
  }

  &.internal-type {
    .line-decor {
      top: 20px;
      bottom: auto;

      &:last-child {
        top: auto;
        bottom: 10px;
      }
    }

    &.who-we-are-section {
      padding-top: ac(60px, 30px);
      padding-bottom: ac(32px, 16px);
    }
  }
}
