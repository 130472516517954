.subscribe-banner {
  padding-top: ac(150px, 60px);
  padding-bottom: ac(120px, 68px);
  .cont {
    position: relative;
  }
  .line-decor {
    margin-bottom: ac(61px, 20px);
    position: absolute;
    top: ac(0, 24px, 320, 840);
  }
  &-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: 840px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-image {
    max-width: ac(638px, 430px, 840);
    width: 100%;
    background-color: #0B2FA0;
    border-radius: ac(32px, 20px);
    z-index: 0;
    overflow: hidden;
    position: relative;
    @media (max-width: 840px) {
      max-width: none;
      max-height: ac(360px, 180px, 320, 840);
    }
    img {
      @media (max-width: 840px) {
        object-position: ac(0, -120px, 320, 840) ac(-140px, -230px, 320, 840);
        height: auto;
        width: auto;
        object-fit: cover;
      }
      @media (max-width: 500px) {
        object-position: ac(-30px, -20px, 320, 500) ac(-190px, -110px, 320, 500);
      }
    }
  }

  &-content {
    background-color: var(--cl-blue-grey);
    max-width: ac(738px, 580px, 840);
    width: 100%;
    border-radius: ac(32px, 20px);
    padding: ac(64px, 22px);
    padding-bottom: ac(56px, 20px);
    position: absolute;
    right: 0;
    z-index: 2;
    @media (max-width: 840px) {
      position: relative;
      right: auto;
      max-width: none;
    }
  }

  &__title {
    margin-bottom: ac(24px, 18px);
    color: var(--cl-blue);
    font-size: ac(72px, 34px);
    font-weight: bold;
    line-height: 1.12;
    letter-spacing: ac(1.08px, 0.48px);
    font-family: var(--font-second);
    span {
      color: var(--cl-pink);
    }
  }
  &__descr {
    color: var(--cl-blue);
    margin-bottom: ac(48px, 28px);
    max-width: ac(610px, 580px);
    font-size: ac(24px, 18px);
    font-weight: 500;
    line-height: 1.52;
  }
  &__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: ac(20px, 16px);
    @media (max-width: 520px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 18px;
    }
    .meta-input {
      width: 100%;
      max-width: ac(406px, 320px);
      position: relative;
      i {
        color: var(--cl-blue);
        font-size: ac(32px, 20px);
        position: absolute;
        left: ac(16px, 12px);
        top: 50%;
        transform: translateY(-50%);
        font-size: ac(27px, 20px);
      }
      input {
        padding-left: ac(51px, 44px);
        color: var(--cl-blue);
        border-color: var(--cl-blue);
        height: ac(46px, 40px);
        font-weight: 400;
        &::placeholder {
          color: var(--cl-blue);
        }
      }
    }
  }
  &__submit {
    background-color: var(--cl-pink);
    .btn__text {
      color: #FFF;
    }
    @media (max-width: 520px) {
      width: 100%;
      max-width: 450px;
    }
  }

  &.post-roles {
    &.subscribe-banner {
      padding-top: ac(100px, 50px);
    }
  }

  &.post-roles {
    &.subscribe-banner {
      padding-top: ac(100px, 50px);
    }
  }

  &.blogs-page {
    &.subscribe-banner {
      padding-top: ac(100px, 60px);
    }
  }
}