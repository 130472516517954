.job-details-first {
  padding-top: ac(194px, 118px);
  padding-bottom: ac(130px, 80px);
  background: rgba(0, 35, 149, 0.7);
  overflow: hidden;
  &__bg {
    &-image {
      position: absolute;
      inset: 0;
      opacity: .9;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    /* &-overlay {
      position: absolute;
      inset: 0;
      background: rgba(0, 35, 149, 0.271);
      mix-blend-mode: overlay;
      &-2 {
        background: #002495;
        mix-blend-mode: color;
      }
    } */
  }
  .cont {
    position: relative;
    z-index: 2;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    gap: ac(56px, 18px, 992);
    @media (max-width: 992px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 32px;
    }
  }
  &__text {
    max-width: ac(630px, 480px);
    width: 100%;
    @media (max-width: 992px) {
      max-width: 820px;
    }
  }
  &__date {
    font-size: ac(22px, 18px);
    font-weight: 500;
    line-height: 1.4;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: ac(24px, 20px);
    /* exmpl: 9 days ago */
    span {
      font-weight: 500;
      font-size: ac(22px, 18px);
      line-height: 140%;
      color: #fff;
    }
  }
  &__title {
    margin-bottom: ac(48px, 28px);
    color: #FFF;
    font-size: ac(64px, 42px);
    line-height: 1.2;
    span {
      color: var(--cl-pink);
    }
  }
  &__links {
    display: flex;
    align-items: center;
    gap: ac(32px, 20px);
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__link {
    &-back {
      .link-btn__text {
        color: #FFF;
        font-size: ac(22px, 20px);
      }
      .link-btn__icon {
        background: transparent;
        color: #FFF;
        border: 1px solid #FFF;
      }
    }
    &-apply {
      background: #FFF;
      @media (max-width: 576px) {
        max-width: 230px;
        width: 100%;
      }
      .btn__text {
        color: rgba(10, 10, 10, 0.8)!important;
        font-size: ac(22px, 20px);
      }
    }
  }
  &__info {
    padding-top: ac(40px, 22px);
    padding-right: ac(48px, 24px);
    padding-bottom: ac(40px, 22px);
    padding-left: ac(48px, 24px);
    border: 2px solid var(--cl-pink);
    border-radius: ac(32px, 20px);
    background: rgba(0, 35, 149, 0.6);
    max-width: ac(594px, 420px, 1024);
    width: 100%;
    height: fit-content;
    @media (max-width: 992px) {
      max-width: 576px;
    }
    &-item {
      color: #FFF;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(237, 240, 248, 0.3);
        margin-bottom: ac(14px, 12px);
        padding-bottom: ac(14px, 12px);
      }
    }
  }

  &.post-roles {
    &.job-details-first {

    }

    .job-details-first__title {
      margin-bottom: 54px;
    }

    .job-details-first__info {
      padding: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}