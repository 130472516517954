.job-details {
  padding-top: ac(80px, 64px);
  padding-bottom: ac(120px, 80px);
  &-wrap {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    position: relative;
    @media (max-width: 640px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  &-content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 70.22%;
    display: flex;
    padding-top: ac(6px, 0);
    @media (max-width: 1100px) {
      width: 62%;
    }
    @media (max-width: 640px) {
      width: 100%;
    }
  }

  /* &__text {

  } */

  &__bottom {
    margin-top: ac(48px, 32px);
  }
  &__links {
    display: flex;
    gap: ac(32px, 24px);
    flex-wrap: wrap-reverse;
  }
  &__link {
    &-back {
      .link-btn__icon {
        background-color: transparent;
        border: 1px solid var(--cl-black);
        i {
          color: var(--cl-black);
        }
      }
      .link-btn__text {
        letter-spacing: 0.11px;
        font-size: ac(22px, 20px);
      }
      &:hover {
        .link-btn__icon i {
          color: var(--cl-white);
        }
      }
    }
    &-apply {
      background-color: var(--cl-pink);
      @media (max-width: 480px) {
        max-width: 100%;
        width: 100%;
      }
      .btn__text {
        color: #fff;
      }
    }
  }
  &__share {
    margin-top: ac(56px, 38px);
    display: flex;
    align-items: center;
    span {
      margin-right: ac(20px, 16px);
      font-size: ac(20px, 18px);
      font-weight: 500;
      line-height: 1.4;
      color: rgba(10, 10, 10, 0.9);
    }
    a {
      display: flex;
      i {
        font-size: ac(36px, 32px);
        color: var(--cl-blue);
        transition: 0.3s all;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
      &:hover {
        i {
          color: rgba(0, 35, 149, 0.8);
        }
      }
    }
  }

  &-aside {
    max-width: ac(305px, 280px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 23.83%;
    display: flex;
    position: sticky;
    top: clamp(86px, 3.57143vw + 58.5714px, 110px);
    transform: translateX(ac(10px, 0));
    right: 0;
    @media (max-width: 851px) {
      width: 34%;
    }
    @media (max-width: 1100px) {
      width: 30%;
    }
    @media (max-width: 640px) {
      width: 100%;
      margin-top: 32px;
      justify-content: center;
      max-width: none;
    }
    &__title {
      margin-bottom: ac(24px, 18px);
      font-size: ac(36px, 24px);
      font-weight: bold;
      color: #010f93;
      text-align: left;
      font-family: 'Nunito Sans', sans-serif;
    }
    &__card {
      max-width: 305px;
      --bottom-height: ac(91px, 50px);
      position: relative;
      z-index: 5;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      cursor: pointer;
      @media (max-width: 640px) {
        --bottom-height: 80px;
      }
      &:hover {
        .job-details-aside__card {
          &-link {
            transform: rotate(-90deg);
          }
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: var(--cl-white);
        height: calc(100% - var(--bottom-height));
        border-radius: 16px 16px 0 0;
        border: 1px solid rgba(0, 35, 149, 0.16);
        border-bottom: none;
        content: '';
        z-index: -1;
      }

      &-bottom {
        /* aspect-ratio: 417 / 104; */
        aspect-ratio: 347 / 105;
        background-image: url('../../assets/static/mask/job-detail-card-bottom.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        pointer-events: none;
        z-index: -2;
        transform: translateZ(0);
      }

      &-image {
        max-width: 305px;
        img {
          border-radius: 12px;
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
      &-text {
        padding-top: ac(20px, 16px);
        padding-left: ac(32px, 14px);
        padding-right: ac(32px, 14px);
        padding-bottom: ac(20px, 18px);
        width: 100%;
      }
      &-name {
        font-size: ac(30px, 18px);
        font-weight: bold;
        line-height: 1.28;
        font-family: var(--font-second);
        margin-bottom: ac(16px, 10px);
        color: var(--cl-blue);
        @media (max-width: 640px) {
          font-size: ac(28px, 20px, 320, 640);
          br {
            display: none;
          }
        }
      }
      &-position {
        color: var(--cl-pink);
        font-size: ac(16px, 14px);
        font-weight: 500;
        line-height: 1.4;
        margin-bottom: ac(28px, 12px);
      }
      &-socials {
        display: flex;
        gap: ac(16px, 8px);
        a {
          font-size: ac(40px, 28px);
          color: var(--cl-blue);
          height: ac(48px, 32px);
          transition: 0.3s all ease-in;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      &-link {
        --size: ac(54px, 36px, 640);
        width: var(--size);
        height: var(--size);
        border: 1px solid rgba(0, 35, 149, 0.16);
        border-radius: 100%;
        color: var(--cl-blue);
        rotate: 45deg;
        position: absolute;
        bottom: ac(5px, -3px, 640);
        right: ac(5px, -3px, 640);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s all;
        @media (max-width: 640px) {
          --size: ac(54px, 50px, 320, 640);
          bottom: 0;
          right: 0;
        }
      }
    }
  }

  &.post-roles {
    &.job-details {
      padding-bottom: ac(108px, 80px);
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      span {
        color: var(--cl-pink);
      }
    }

    h1 {
      font-family: var(--font-second);
      font-size: ac(50px, 38px);
      font-weight: 700;
      letter-spacing: ac(1.2px, 0.5px);
      line-height: 1.2;
      color: var(--cl-primary);
      margin-bottom: ac(24px, 20px);

      &:last-of-type {
        margin-bottom: ac(32px, 26px);
      }
    }
    h2 {
      font-weight: 700;
      font-size: ac(42px, 32px);
      line-height: 1.28;
      letter-spacing: 0.3px;
      color: var(--cl-primary);
      margin-bottom: ac(24px, 18px);
      padding-top: ac(48px, 20px) !important;
    }
    h3 {
      font-weight: 700;
      font-size: ac(35px, 28px);
      line-height: 1.28;
      letter-spacing: ac(0.45px, 0.36px);
      color: var(--cl-primary);
      padding-top: ac(40px, 20px) !important;
      text-transform: none !important;
      margin-bottom: ac(24px, 18px);
    }
    h4 {
      font-size: ac(28px, 24px);
      font: 700 ac(28px, 24px) var(--font-second) !important;
      margin-bottom: ac(24px, 18px);
      padding-top: ac(40px, 20px) !important;
      &:last-of-type {
        margin-bottom: ac(32px, 26px);
      }
    }
    h5 {
      color: var(--cl-black);
      font-size: ac(28px, 22px);
      margin-bottom: ac(20px, 16px);
      font-weight: 700;
      padding: 0;
      &:not(:first-of-type) {
        margin-top: ac(24px, 20px);
      }
    }
    h6 {
      color: var(--cl-primary);
      font-size: ac(22px, 20px);
      line-height: 148%;
      margin-bottom: ac(16px, 12px);
      padding: 0;
    }

    ul,
    ol {
      /*margin-top: ac(32px, 20px);
      margin-bottom: ac(48px, 22px);*/

      &:not(.our-team__name-list) {
        li {
          line-height: 1.48;
          letter-spacing: 0.01em;
          color: rgba(10, 10, 10, 0.9);
          font: 400 ac(22px, 18px) var(--font-main) !important;
          &:not(:last-child) {
            margin-bottom: ac(12px, 8px);
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(16px, 10px);
        }
      }
    }

    .our-team__name-list {
      margin: 0;
    }

    .our-team__social-list {
      li {
        margin-bottom: 0 !important;
      }
    }

    p {
      font: 400 ac(20px, 18px) var(--font-main) !important;
      line-height: 140%;
      font-weight: 400;
      &:not(:last-child) {
        margin-bottom: ac(16px, 10px);
      }
    }
    p:first-child {
      strong {
        color: var(--cl-blue);
        font: 500 ac(22px, 20px) var(--font-main) !important;
      }
    }
    p {
      strong {
        font: 700 ac(20px, 18px) var(--font-main) !important;

        /*display: block;*/
      }
    }

    .job-details__card {
      position: relative;
      width: 28.2%;
      z-index: 10;

      padding: 20px;

      &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 11;

        border-radius: 50%;
        border: 1px solid #00249529;
        width: 54px;
        height: 54px;

        i {
          color: var(--cl-primary);
          font-size: 26px;
        }
      }

      &-wrapper {
        z-index: 12;
        min-width: 100%;

        &-top {
          display: flex;
          flex-wrap: wrap;
        }
      }

      &-name {
        color: var(--cl-primary);
        font-size: ac(22px, 20px);
        font-weight: 700;
        line-height: 126%;
      }

      &-position {
        color: var(--cl-pink);
        font-weight: 500;
        font-size: ac(14px, 12px);
        line-height: 140%;
      }

      &-images {
        position: relative;
        width: 72px;
        height: 72px;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-bg,
      &-img {
        position: absolute;
        inset: 0;
      }

      &-image {
        position: absolute;
        z-index: -1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &.post-roles {
    &.job-details {
      padding-bottom: ac(108px, 80px);
    }

    .job-details-wrap {
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .job-details-content {
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .job-details__card {
      position: relative;
      width: 29%;
      z-index: 10;

      padding: ac(20px, 30px);
      --bottom-height: 90px;
      top: 0;

      @media (max-width: 1200px) {
        --bottom-height: 80px;

        width: 34%;
      }

      @media (max-width: 990px) {
        --bottom-height: 80px;
      }

      @media (max-width: 910px) {
        width: 35%;
      }

      @media (max-width: 768px) {
        min-width: 305px;
        width: 100%;
        margin: 32px auto 0;
      }

      @media (max-width: 640px) {
        margin: 32px auto;
        --bottom-height: 150px;
      }

      @media (max-width: 530px) {
        --bottom-height: 130px;
      }

      @media (max-width: 440px) {
        width: 100%;
        --bottom-height: 95px;
        margin: 32px auto;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: var(--cl-white);
        height: calc(100% - var(--bottom-height));
        border-radius: 16px 16px 0 0;
        border: 1px solid rgba(0, 35, 149, 0.16);
        border-bottom: none;
        content: '';
        z-index: -1;
      }

      &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 11;

        border-radius: 50%;
        border: 1px solid #00249529;
        width: 54px;
        height: 54px;
        transition: 0.3s;
        transform: rotate(45deg);
        cursor: pointer;

        i {
          color: var(--cl-primary);
        }

        &:hover {
          transform: rotate(0deg);
        }
      }

      &-wrapper {
        z-index: 12;
        min-width: 100%;

        &-top {
          display: flex;
          align-items: center;
          gap: 20px;

          &-info {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        }

        &-bottom {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          margin-top: 32px;

          gap: ac(16px, 12px);
        }
      }

      &-name {
        color: var(--cl-primary);
        font-size: ac(22px, 17px);
        font-weight: 700;
        line-height: 126%;
      }

      &-position {
        color: var(--cl-pink);
        font-weight: 500;
        font-size: ac(14px, 12px);
        line-height: 140%;
      }

      &-social {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: ac(40px, 28px);

        i {
          color: var(--cl-primary);
        }
      }

      &-images {
        position: relative;
        width: 72px;
        height: 72px;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-bg,
      &-img {
        position: absolute;
        inset: 0;
      }

      &-image {
        aspect-ratio: 347 / 105;
        background-image: url('../../assets/static/mask/job-detail-card-bottom.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        pointer-events: none;
        z-index: -2;
        transform: translateZ(0);
      }
    }
  }

  &.post-roles {
    .job-details-aside__card {
      position: relative;
      padding: 20px;
    }

    .job-details__card-button {
      right: 3px;
      bottom: 3px;
    }
  }
}
