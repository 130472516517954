.case-studies-section {
  padding-top: ac(60px, 30px);
  padding-bottom: ac(70px, 30px);
  overflow: hidden;
  position: relative;
  z-index: 20;

  &__wrap {
    padding-top: ac(60px, 30px);
    padding-bottom: ac(60px, 30px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    &:before {
      position: absolute;
      z-index: -1;
      content: '';
      left: ac(-59px, -24px);
      right: ac(-59px, -24px);
      top: 0;
      bottom: 0;
      background: var(--gradient);

      @media (max-width: 640px) {
        left: calc((100vw - 89.33%) / 2 * -1);
        right: calc((100vw - 89.33%) / 2 * -1);
      }

      @mixin min-media 1450 {
        left: calc((100vw - 1302px) / 2 * -1);
        right: calc((100vw - 1302px) / 2 * -1);
      }
    }
  }

  &__slider {
    overflow: hidden;

    .slider-buttons {
      width: 100%;
      justify-content: flex-end;
      .slider-btn {
        margin-top: ac(40px, 32px);
      }
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    min-height: ac(491px, 300px);
  }

  &__item-title {
    width: 100%;
    max-width: ac(749px, 400px);

    &:not(:last-child) {
      margin-bottom: ac(60px, 34px);
    }
  }

  &__item-buttons {
  }
}
