.markets-section {
  padding-top: ac(70px, 30px);
  padding-bottom: ac(70px, 30px);
  overflow: hidden;
  position: relative;
  z-index: 20;

  &__wrap {
    padding-top: ac(60px, 30px);
    padding-bottom: ac(80px, 30px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    &:before {
      position: absolute;
      z-index: -1;
      content: '';
      left: ac(-59px, -24px);
      right: ac(-59px, -24px);
      top: 0;
      bottom: 0;
      background: var(--cl-primary);

      @media (max-width: 640px) {
        left: calc((100vw - 89.33%) / 2 * -1);
        right: calc((100vw - 89.33%) / 2 * -1);
      }

      @mixin min-media 1450 {
        left: calc((100vw - 1302px) / 2 * -1);
        right: calc((100vw - 1302px) / 2 * -1);
      }
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(76px, 40px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    padding-top: 1px;
    overflow: visible;

    .slider-buttons {
      .slider-btn {
        margin-top: ac(30px, 20px);
      }
    }

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: ac(30px, 10px);
        grid-row-gap: ac(41px, 24px);
      }

      .slider-buttons {
        display: none;
      }
    }

    &.swiper-initialized {
      width: 100%;
      overflow: visible;

      .swiper-wrapper {
        min-height: 204px;
        max-height: 276px;
      }

      .markets-section__item {
        margin-top: 0 !important;
        margin-bottom: 30px !important;
      }

      @mixin media 591 {
        max-width: 340px;
        margin-left: 0;
      }
    }
  }

  &__item {
    padding-top: 10px;
    border-top: 1px solid var(--cl-white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(19px, 12px);
  }

  &__item-icon {
    @mixin aspect-ratio 1, 1;
    width: ac(50px, 48px);

    img {
      object-fit: contain;
    }
  }

  &__item-title {
    flex: 1;
  }
}
