.clients-say-section {
  margin-top: ac(70px, 40px);
  margin-bottom: ac(74px, 40px);
  padding-top: ac(120px, 60px);
  position: relative;
  z-index: 5;
  overflow: hidden;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ac(530px, 320px);
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    width: 100%;
    text-align: center;
    color: var(--cl-white);

    &.heading-2 {
      font-family: var(--font-second);
      font-size: ac(64px, 40px);
    }

    &:not(:last-child) {
      margin-bottom: ac(64px, 30px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    .swiper-button {
      margin-top: ac(56px, 30px);
    }
  }

  &.esmos-page {
    &.clients-say-section {
      padding-top: ac(80px, 30px);
      margin-top: ac(60px, 30px);
      margin-bottom: ac(80px, 40px);
    }
    .testimonial-card {
      height: auto;
    }
    .line-decor {
      top: -25px;
    }
  }
}
