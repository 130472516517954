/*@font-face {
  font-family: 'Gellix';
  src: url('../../assets/fonts/Gellix/Gellix-Bold.eot');
  src: local('Gellix Bold'), local('Gellix-Bold'),
    url('../../assets/fonts/Gellix/Gellix-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Gellix/Gellix-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Gellix/Gellix-Bold.woff') format('woff'),
    url('../../assets/fonts/Gellix/Gellix-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gellix';
  src: url('../../assets/fonts/Gellix/Gellix-Regular.eot');
  src: local('Gellix Regular'), local('Gellix-Regular'),
    url('../../assets/fonts/Gellix/Gellix-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Gellix/Gellix-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Gellix/Gellix-Regular.woff') format('woff'),
    url('../../assets/fonts/Gellix/Gellix-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}*/

@font-face {
  font-family: 'Gellix';
  src: url('../../assets/fonts/Gellix/gellix-semibold_1-webfont.woff2')
      format('woff2'),
    url('../../assets/fonts/Gellix/gellix-semibold_1-webfont.woff')
      format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gellix';
  src: url('../../assets/fonts/Gellix/Gellix-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*h1 {
  font-size: ac(140px, 54px);
  font-weight: 300;
  line-height: normal;
  color: var(--cl-primary);

  @mixin media 374 {
    font-size: 40px;
  }

  @mixin media 330 {
    font-size: 38px;
  }
}

h2 {
  font-size: ac(90px, 44px);
  font-weight: 300;
  line-height: 1.15;
  color: var(--cl-primary);

  @media (max-width: 360px) {
    font-size: 25px;
  }
}

h3 {
  font-size: ac(35px, 26px);
  font-weight: 300;
  line-height: normal;
  color: var(--cl-primary);

  &.big-size {
    font-size: ac(90px, 44px);
    font-weight: 300;
    line-height: 1.1;
    color: var(--cl-primary);

    @media (max-width: 360px) {
      font-size: 25px;
    }
  }
}

h4 {
  font-size: ac(30px, 20px);
  font-weight: 300;
  line-height: normal;
}

h5 {
  font-size: ac(28px, 20px);
  font-weight: 500;
  line-height: 1.5;
}

h6 {
  font-size: ac(24px, 18px);
  font-weight: 500;

  line-height: 2;
}*/

/*p {
  font-size: ac(20px, 16px);
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
}

li {
  font-size: ac(20px, 16px);
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
}

a {
  font-size: ac(20px, 16px);
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
}*/

.heading-1 {
  font-family: var(--font-second);
  font-size: ac(80px, 42px);
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: ac(1px, 0.42px);

  @media (max-width: 360px) {
    font-size: 38px;
  }
}

.heading-2 {
  font-family: var(--font-second);
  font-size: ac(72px, 40px);
  font-weight: 700;
  letter-spacing: ac(1.2px, 0.5px);
  line-height: normal;

  @media (max-width: 360px) {
    font-size: 36px;
  }
}

.heading-64 {
  font-family: var(--font-second);
  font-size: ac(64px, 34px);
  font-weight: 700;
  line-height: normal;

  @media (max-width: 360px) {
    font-size: 32px;
  }
}

.heading-3 {
  font-family: var(--font-second);
  font-size: ac(50px, 30px);
  font-weight: 700;
  letter-spacing: ac(1.2px, 0.5px);
  line-height: normal;

  @media (max-width: 360px) {
    font-size: 28px;
  }
}

.heading-4 {
  font-family: var(--font-second);
  font-size: ac(40px, 26px);
  font-weight: 700;
  letter-spacing: ac(1.2px, 0.5px);
  line-height: 1.55;

  @media (max-width: 360px) {
    font-size: 22px;
  }
}

.heading-4-second {
  font-family: var(--font-second);
  font-size: ac(34px, 24px);
  font-weight: 700;
  line-height: 129.41%;
  letter-spacing: 0;

  @media (max-width: 360px) {
    font-size: 22px;
  }
}

.heading-5 {
  font-family: var(--font-second);
  font-size: ac(30px, 22px);
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0;

  @media (max-width: 360px) {
    font-size: 21px;
  }
}

.heading-6 {
  font-family: var(--font-second);
  font-size: ac(30px, 22px);
  font-weight: 700;
  line-height: 1.28;
  letter-spacing: 0.3px;

  @media (max-width: 360px) {
    font-size: 21px;
  }
}

.heading-1,
.heading-2,
.heading-3 {
  span {
    &[data-word='.'] {
      color: var(--cl-pink);
    }
  }

  @media (max-width: 451px) {
    span {
      padding-bottom: 2px;
    }
  }
}

.subtitle-3 {
  font-size: ac(24px, 20px);
  font-weight: 500;
  line-height: 1.52;
  letter-spacing: 0.12px;
}

.content-element {
  font-size: ac(20px, 16px);
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;

  &[data-scroll] {
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: calc((ac(20px, 16px) * 1.3) * 6);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    span {
      color: var(--cl-pink);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    a {
      font: inherit;
      color: var(--cl-black);
      text-decoration: underline;
      transition: color 0.3s ease;
      word-wrap: break-word;
      overflow-wrap: break-word;

      &:hover {
        color: var(--cl-primary);
      }
    }
  }

  h3,
  h4 {
    &:not(:last-child) {
      margin-bottom: 13px;
    }
  }

  li,
  p {
    /*Not Design*/
    &:not(:last-child) {
      margin-bottom: ac(5px, 8px);
    }
  }

  ol {
    list-style-type: none;
    counter-reset: num;
    display: grid;
    li {
      position: relative;
      padding-left: ac(24px, 14px);
      &:before {
        position: absolute;
        color: var(--cl-black);
        top: 0;
        left: ac(5px, 0px);
        font: inherit;
        content: counter(num) '.';
        counter-increment: num;
      }

      &:not(:last-child) {
        margin-bottom: ac(10px, 8px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }
  }

  ul {
    display: grid;

    li {
      position: relative;
      display: block;
      padding-left: ac(26px, 14px);

      &:before {
        position: absolute;
        background: var(--cl-black);
        border-radius: 50%;
        content: '';
        width: 5px;
        height: 5px;
        top: 8px;
        left: ac(11px, 4px);
      }

      &:not(:last-child) {
        margin-bottom: ac(10px, 8px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }
  }

  &.small-size {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.005em;

    p,
    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.005em;
    }

    p,
    ul,
    ol {
      &:not(:last-child) {
        margin-bottom: ac(14px, 8px);
      }
    }
  }

  &.medium-size {
    font-size: ac(22px, 18px);
    font-weight: 400;
    line-height: 1.36;
    letter-spacing: 0;

    p,
    li {
      font-size: ac(22px, 18px);
      font-weight: 400;
      line-height: 1.36;
      letter-spacing: 0;
    }

    &.fw-500 {
      font-weight: 500;
    }

    p,
    ul,
    ol {
      &:not(:last-child) {
        margin-bottom: ac(16px, 10px);
      }
    }
  }

  &.big-size {
    font-size: ac(26px, 20px);
    font-weight: 500;
    line-height: 1.36;
    letter-spacing: 0;

    p,
    li {
      font-size: ac(26px, 20px);
      font-weight: 500;
      line-height: 1.36;
      letter-spacing: 0;
    }
  }

  .simplebar-track.simplebar-vertical {
    width: ac(3px, 2px);
    background: var(--cl-grey);
    border-radius: 0;

    .simplebar-scrollbar {
      background: var(--cl-sun);
      border-radius: 0;

      &:before {
        content: none;
      }
    }
  }

  &.article-type {
    h2,
    h3 {
      text-transform: none !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--cl-pink) !important;
    }

    h4 {
      letter-spacing: ac(0.75px, 0.48px);
      color: var(--cl-navy);
      margin-bottom: ac(22px, 18px);
      font: bold ac(50px, 32px) / 1.2 var(--font-second) !important;
    }

    h6 {
      letter-spacing: ac(0.45px, 0.36px);
      color: var(--cl-navy);
      margin-bottom: ac(32px, 20px);
      font: bold ac(30px, 24px) / 1.28 var(--font-second) !important;
    }

    table {
      border: 1px solid var(--cl-navy) !important;
      border-color: var(--cl-navy) !important;
      width: 100% !important;

      &:not(:last-child) {
        margin-bottom: ac(14px, 10px);
      }

      tr,
      thead {
        &:not(:last-child) {
          border-bottom: 1px solid var(--cl-navy);
        }
      }

      td,
      th {
        border-color: var(--cl-navy) !important;
        background: transparent !important;
        padding: ac(10px, 4px);

        &:not(:last-child) {
          border-right: 1px solid var(--cl-navy) !important;
        }
      }
    }

    p,
    ol,
    li,
    ul {
      letter-spacing: ac(0.11px, 0.09px);

      font: 400 ac(22px, 18px) / 1.48 var(--font-main) !important;

      color: rgba(10, 10, 10, 0.9) !important;
      margin-bottom: ac(14px, 10px);
      text-align: left !important;
      margin-left: 0 !important;

      span {
        font: inherit !important;
        color: inherit !important;
        text-align: left !important;
      }

      &.paragraph {
        font: 700 ac(22px, 18px) / 1.48 var(--font-main) !important;
      }

      strong,
      b,
      .Strong,
      .strong,
      .paragraph {
        font: 700 ac(22px, 18px) / 1.48 var(--font-main) !important;
      }

      + h2 {
        padding-top: ac(40px, 30px);
      }

      + h3 {
        padding-top: ac(30px, 20px);
      }

      + h4 {
        padding-top: ac(30px, 20px);
      }
      + h6 {
        padding-top: ac(36px, 22px);
      }

      a {
        font-weight: 600 !important;
        color: var(--cl-blue) !important;
        text-decoration-color: transparent;
        transition: 0.3s all;
        display: inline;
        &:hover {
          text-decoration-color: var(--cl-blue) !important;
        }
      }
    }

    ul {
      list-style-type: none !important;
      padding-left: 0;
      li {
        position: relative;
        padding-left: ac(24px, 14px);
        &:not(:last-child) {
          margin-bottom: ac(12px, 10px);
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: ac(12px, 8px);
          --size: 8px;
          height: var(--size);
          width: var(--size);
          min-width: var(--size);
          border-radius: 100%;
          background-color: var(--cl-pink);
        }

        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }

    p {
      letter-spacing: ac(0.11px, 0.09px);
      word-break: break-word;
      &:not(:last-child) {
        margin-bottom: ac(14px, 10px);
      }

      + ul {
        padding-top: 2px;
      }

      + ol {
        padding-top: 2px;
      }
    }

    &.first-big-paragraph {
      p {
        &:first-child {
          font-family: var(--font-second);
          font-size: ac(30px, 22px);
          font-weight: 500;
          line-height: 113.33%;

          &:not(:last-child) {
            margin-bottom: ac(50px, 30px);
          }
        }
      }
    }

    img {
      overflow: hidden;
      border-radius: 20px;
      object-fit: contain;
      object-position: left;
      max-height: ac(900px, 700px);
      width: 100%;

      &:not(:first-child) {
        margin-top: ac(40px, 22px);
      }

      &:not(:last-child) {
        margin-bottom: ac(50px, 30px);
      }
    }
  }
}

.subtitle-1 {
  font-family: var(--font-second);
  font-size: ac(30px, 22px);
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: ac(1.2px, 0.5px);
}

.subtitle-2 {
  font-family: var(--font-second);
  font-size: ac(26px, 18px);
  font-weight: 500;
  line-height: 1.52;
}

.subtitle-3 {
  font-family: var(--font-second);
  font-size: ac(24px, 16px);
  font-weight: 500;
  line-height: 1.52;
}

.subtitle-4 {
  font-family: var(--font-second);
  font-size: ac(22px, 14px);
  font-weight: 500;
  line-height: 1.52;
}

.dark-bg {
  color: var(--cl-white);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--cl-white);
  }

  .content-element {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li {
      a {
        color: var(--cl-white);

        &:hover {
          color: var(--cl-primary);
        }
      }
    }
  }
}
