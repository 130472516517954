.looking-section {
  padding-top: ac(80px, 40px);
  padding-bottom: 40px;
  position: relative;
  z-index: 5;
  overflow: hidden;

  &__list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: 20px;
    width: 100%;
  }

  &__item {
    min-height: ac(432px, 300px);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--cl-white);

    &:before {
      position: absolute;
      --size: ac(124px, 100px);
      width: var(--size);
      height: var(--size);
      z-index: 1;
      mask-image: url('../../assets/static/mask/radius-mask.svg');
      mask-size: 100% 100%;
      mask-repeat: no-repeat;
      background: var(--cl-white);
      top: -0.5px;
      right: -0.5px;
      content: '';
    }

    &:hover {
      .looking-section {
        &__item-link {
          background-color: var(--cl-pink);
          i {
            transform: rotate(0);
          }
        }
      }
    }
  }

  &__item-image {
    position: absolute;
    inset: 0;
    border-radius: ac(24px, 20px);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.3s ease;
    }

    &:before,
    &:after {
      position: absolute;
      inset: 0;
      content: '';
      background: var(--cl-blue);
    }

    &:before {
      position: absolute;
      z-index: 1;
      mix-blend-mode: overlay;
      opacity: 0.3;
      transition: opacity 0.3s ease;
    }

    &:after {
      position: absolute;
      z-index: 2;
      mix-blend-mode: color;
    }
  }

  &__item-content {
    margin-top: auto;
    padding: ac(40px, 24px) ac(40px, 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 5;
  }

  &__item-title {
    --max-line-num: 4;
    letter-spacing: ac(1.2px, 0.5px);
    line-height: 1.2;
    span {
      color: var(--cl-pink);
    }
  }

  &__item-link {
    position: absolute;
    top: ac(7px, 4px);
    right: ac(0px, 4px);
    --size: ac(80px, 64px);
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(24px, 20px);
    color: var(--cl-white);
    cursor: pointer;
    background: var(--cl-blue);
    transition: background-color 0.3s ease;
    z-index: 10;

    i {
      transform: rotate(-45deg);
      transition: transform 0.3s ease;
    }
  }

  @media (max-width: 640px) {
    .looking-section {
      &__list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }

  + .who-we-are-section {
    padding-top: ac(40px, 0px);
  }

  &.about-page {
    padding-top: ac(60px, 30px);
    padding-bottom: ac(120px, 60px);
  }

  &.areas-page {
    &.looking-section {
      background: var(--cl-blue-grey);
      padding-bottom: ac(120px, 60px);
    }

    .looking-section__item {
      &:before {
        background: var(--cl-blue-grey);
      }
    }
  }

  &.esmos-page {
    &.looking-section {
      padding-top: ac(120px, 60px);
      padding-bottom: ac(120px, 60px);
      background: var(--cl-blue-grey);
    }

    .looking-section__item {
      &:before {
        background: var(--cl-blue-grey);
      }
    }
  }
}
