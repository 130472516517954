.teams-section {
  padding-top: ac(70px, 30px);
  padding-bottom: ac(70px, 30px);
  overflow: hidden;
  position: relative;
  z-index: 20;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(75px, 40px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    padding-top: 1px;
    overflow: visible;

    .slider-buttons {
      .slider-btn {
        margin-top: ac(40px, 32px);
      }
    }

    @mixin media 551 {
      max-width: 290px;
      margin-left: 0;
    }

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column-gap: ac(30px, 10px);
        grid-row-gap: ac(30px, 20px);

        @mixin media 1100 {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }

      .slider-buttons {
        display: none;
      }
    }

    &.swiper-initialized {
      width: 100%;
      overflow: visible;
      /*max-width: 600px;*/
    }
  }
}
