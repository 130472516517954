.services-section {
  padding-top: ac(45px, 30px);
  padding-bottom: ac(70px, 30px);
  position: relative;
  z-index: 10;
  overflow: hidden;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(75px, 40px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    overflow: visible;

    .slider-buttons {
      .slider-btn {
        margin-top: ac(40px, 32px);
      }
    }
  }

  &__item {
    border-top: 1px solid var(--cl-primary);
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item-subtitle {
    font-size: 17px;
    font-weight: 700;
    color: var(--cl-primary);
    line-height: normal;
    letter-spacing: 0.015em;

    &:not(:last-child) {
      margin-bottom: 9px;
    }
  }

  &__item-title {
    font-size: ac(30px, 22px);
    font-weight: 300;
    line-height: normal;
    color: var(--cl-black);

    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }

  &__item-text {
    width: 100%;
  }
}
