.text-image-section {
  padding-top: ac(80px, 30px);
  padding-bottom: ac(80px, 30px);
  background: var(--cl-blue-grey);
  z-index: 5;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-about {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__image {
    width: 49.22%;
    border-radius: ac(24px, 20px);

    --arw: 630;
    --arh: 382;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 45.78%;

    &-about {
      width: 46.41%;
    }
  }

  &__title {
    color: var(--cl-primary);

    padding-bottom: ac(24px, 14px);

    &.heading-3 {
      line-height: 1.2;
    }

    span {
      color: var(--cl-pink);
    }
  }

  &__subtitle {
    color: var(--cl-primary);
    font-family: var(--font-main);

    padding-bottom: ac(24px, 16px);

    @media (max-width: 950px) {
      font-size: 18px;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__about {
    &-image {
      width: 49.22%;
      height: auto;
      border: 1px solid var(--cl-blue-grey);
      border-radius: ac(32px, 24px);
      overflow: hidden;
    }

    &-text {
      padding-bottom: ac(32px, 16px);
      p {
        font-size: ac(18px, 16px);
      }
    }
  }

  @media (max-width: 950px) {
    .text-image-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 30px;

        &-about {
          flex-direction: column;
          grid-row-gap: 30px;
        }
      }

      &__about {
        &-image {
          width: 100%;
          max-width: 600px;
        }
      }

      &__image {
        width: 100%;
        max-width: 600px;
      }

      &__content {
        width: 100%;

        &-about {
          width: 100%;
        }
      }
    }
  }

  &.about-page {
    background: var(--cl-white);

    &.text-image-section {
      padding-top: ac(97.5px, 67.5px);
      padding-bottom: ac(68.75px, 30px);
    }

    @media (min-width: 951px) {
      .text-image-section {
        &__about-image {
          --arw: 630;
          --arh: 892;

          &:after {
            aspect-ratio: 630 / 892;
            --overlay-left: 0px;
          }
        }
      }
    }
  }

  &.esmos-page {
    padding-top: ac(93px, 47px);
    padding-bottom: ac(47px, 30px);

    background: var(--cl-white);

    .text-image-section__title {
    }
  }

  &.areas-type {
    &.text-image-section {
      padding-top: ac(32px, 16px);
      padding-bottom: ac(60px, 30px);
    }

    .text-image-section {
      &__about-image {
        --arw: 630;
        --arh: 382;

        &:after {
          --overlay-left: 0px;
        }
      }
    }
  }
}
