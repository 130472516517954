.deliver-section {
  padding-top: ac(47px, 30px);
  padding-bottom: ac(80px, 40px);

  z-index: 5;

  background: var(--cl-blue-grey);

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: auto;
    z-index: 1;

    height: 275px;

    background: var(--cl-white);

    @media (max-width: 540px) {
      height: 135px;
    }
  }

  .line-decor {
    top: 20px;

    @media (min-width: 1800px) {
      top: -15px;
    }
  }

  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;

    gap: ac(48px, 24px);

    padding: 0 ac(64px, 32px);
    padding-top: ac(80px, 40px);
    padding-bottom: ac(64px, 32px);

    z-index: 3;
  }

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: ac(24px, 12px);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    color: var(--cl-white);

    span {
      color: var(--cl-pink);
    }
  }

  &__list {
    width: 100%;

    @media (min-width: 1201px) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    @media (max-width: 1200px) {
      overflow: visible;
      flex-wrap: nowrap;
      justify-content: flex-start;
      gap: 0;
    }
  }

  &__item {
    border: 1px solid #edf0f866;
    border-radius: ac(20px, 12px);
    width: 100%;
    height: auto;

    @media (max-width: 1200px) {
      width: 100%;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      padding: 22px 28px 32px;

      gap: 8px;
    }

    &-top {
      display: flex;
      align-items: center;

      gap: ac(8px, 6px);
    }

    &-image {
      @mixin aspect-ratio 1, 1;
      width: ac(64px, 50px);
      min-width: ac(64px, 50px);
    }

    &-content {
      color: var(--cl-white);
    }

    &-title {
      color: var(--cl-pink);
    }

    @media (max-width: 950px) {
      width: calc((100% / 2) - 20px);
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &__slider {
    width: 100%;
    overflow: hidden;

    .swiper-buttons {
      display: none;
      padding-top: 20px;

      .swiper-button {
        &:hover {
          background: var(--cl-pink);
        }
      }

      @media (max-width: 1200px) {
        display: flex;
      }
    }
  }
}
