.btn {
  cursor: pointer;
  border: 1px solid var(--cl-pink);
  padding: 15px ac(39px, 24px);
  border-radius: 40px;
  transition: all 0.3s ease;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__text {
    font-family: var(--font-main);
    font-size: ac(24px, 20px);
    font-weight: 600;
    line-height: normal;
    color: var(--cl-black);
    transition: color 0.3s ease, transform 0.3s ease;
    transform: scale(1);
  }

  &:hover {
    background-color: var(--cl-pink);

    .btn__text {
      color: var(--cl-white);
      transform: scale(1.05);
    }
  }

  &.btn-pink {
    color: var(--cl-white);

    background: var(--cl-pink);

    .btn__text {
      color: var(--cl-white);
    }

    &:hover {
      background: var(--cl-white);

      .btn__text {
        color: var(--cl-pink);
      }
    }
  }

  &.btn-white-bg {
    background: var(--cl-white);
    border-color: var(--cl-white);

    &:hover {
      background: var(--cl-pink);
      border-color: var(--cl-pink);


      .btn__text {
        color: var(--cl-white);
      }
    }

    .btn__text {
      color: var(--cl-black);
    }
  }



  &.btn-white {
    border-color: var(--cl-white);

    .btn__text {
      color: var(--cl-white);
    }

    &:hover {
      border-color: var(--cl-blue-grey);
      background-color: var(--cl-blue-grey);

      .btn__text {
        color: var(--cl-blue);
      }
    }
  }

  &.btn-blue {
    border-color: var(--cl-blue);
    background-color: var(--cl-blue);

    .btn__text {
      color: var(--cl-white);
    }

    &:hover {
      border-color: var(--cl-pink);
      background-color: var(--cl-pink);

      .btn__text {
        color: var(--cl-white);
      }
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex-wrap: wrap;

  gap: ac(16px, 12px);

  @media (max-width: 551px) {
    width: 100%;

    .btn {
      width: 100%;
    }
  }
}

.link-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 12px;
  cursor: pointer;

  &__text {
    font-size: ac(24px, 20px);
    font-weight: 600;
    line-height: 1.36;
    color: var(--cl-black);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cl-pink);
    border-radius: 50%;
    --size: ac(36px, 34px);
    width: var(--size);
    height: var(--size);
    transition: background-color 0.3s ease;

    i {
      transform: rotate(45deg);
      color: var(--cl-white);
      transition: all 0.3s ease;
      font-size: ac(15px, 14px);
    }
  }

  &:hover {
    .link-btn {
      &__icon {
        background-color: var(--cl-blue);
        i {
          transform: rotate(0);
        }
      }
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: 551px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    .btn {
      width: 100%;
    }
  }
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  color: var(--cl-white);
  transition: color 0.3s ease, transform 0.3s ease;

  &:hover {
    color: var(--cl-pink);
    transform: scale(1.1);
  }
}

.socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ac(12px, 10px);
}

.swiper-buttons {
  width: fit-content;
  display: flex;
  gap: 16px;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  margin: 0 auto;

  .swiper-button {
    position: relative;
    transform: none;
    margin: 0;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    display: flex;
    --size: ac(62px, 55px);
    width: var(--size);
    height: var(--size);
    justify-content: center;
    align-items: center;
    color: var(--cl-blue, #346c50);
    border: 1px solid var(--cl-blue, #346c50);
    border-radius: 50%;
    transition: 0.3s;
    background-color: var(--cl-white);

    &::after {
      display: none;
    }
    &::before {
      content: '\e904';
      display: block;
      font-family: 'icomoon' !important;
      font-size: 24px;
    }
    &.swiper-button-prev {
      &::before {
        content: '\e901';
      }
    }
    &:hover {
      background: var(--cl-blue, #346c50);
      color: var(--cl-white, #fff);
    }
    &.swiper-button-lock {
      display: none;
    }
  }
}

.arrow-btn {
  --size: ac(59px, 55px);
  border: 1px solid var(--cl-primary);
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: border-color 0.2s ease, background-color 0.3s ease,
    padding 0.3s ease;
  transform-origin: left;
  margin-left: -1px;
  background: var(--cl-white);
  cursor: pointer;

  i {
    font-size: 24px;
    position: relative;
    z-index: 5;
    color: var(--cl-primary);
    transition: color 0.3s ease;
  }

  &:hover {
    background-color: var(--cl-primary);

    i {
      color: var(--cl-white);
    }
  }

  &.white-btn {
    background-color: transparent;
    border-color: var(--cl-white);

    i {
      color: var(--cl-white);
    }

    &:hover {
      background-color: var(--cl-white);

      i {
        color: var(--cl-primary);
      }
    }
  }
}

.btn-show-hide {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 8px;
  font-size: ac(20px, 18px);
  cursor: pointer;
  transition: color 0.3s ease;
  color: #272229;
  font-weight: 500;
  &__icon {
    border: 1px solid var(--cl-pink);
    width: ac(36px, 32px);
    height: ac(36px, 32px);
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;

    i {
      font-size: ac(13px, 12px);
      color: var(--cl-pink);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: opacity 0.3s ease;
    }
  }

  &__show {
    display: flex;
  }

  &__hide {
    display: none;
  }

  &:not(.is-visible) {
    opacity: 0;
    display: none;
  }

  &:not(.active) {
    .btn-show-hide__icon {
      i.icon-plus-second {
        opacity: 1;
      }
    }
  }

  &.active {
    .btn-show-hide__show {
      display: none;
    }

    .btn-show-hide__hide {
      display: flex;
    }

    .btn-show-hide__icon {
      i.icon-plus-second {
        opacity: 0;
      }
    }
  }

  &:hover {
    color: var(--cl-pink);
  }
}

.filters-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-left: 20px;
  padding-right: 20px;
  border-color: var(--cl-pink);

  &__icon {
    width: ac(16px, 14px);
    height: ac(16px, 14px);

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    i {
      font-size: 16px;
      transition: all 0.3s ease;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);

      &.icon-close {
        opacity: 0;
        color: var(--cl-navy);
      }
    }
  }

  &.active {
    .filters-btn__icon {
      i.icon-close {
        opacity: 1;
      }

      i.icon-filters {
        opacity: 0;
      }
    }
  }

  &:not(.active) {
    &:hover {
      .filters-btn__icon {
        i {
          color: var(--cl-white);
        }
      }
    }
  }

  &:hover {
    .filters-btn__icon {
      transform: none;
    }
  }
}