.global-section {
  padding-top: ac(80px, 60px);
  padding-bottom: ac(60px, 30px);

  z-index: 1;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    gap: ac(64px, 32px);
  }

  &__title {
    color: var(--cl-primary);

    span {
      color: var(--cl-pink);
    }
  }

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item {
    position: relative;
    padding: 20px;
    z-index: 1;

    border-radius: ac(32px, 24px);
    overflow: hidden;

    height: 100%;
    min-height: 467px;

    /*&:after {
      position: absolute;
      inset: 20px;
      border: ac(5px, 4px) solid var(--cl-pink);
      content: '';
      border-radius: ac(24px, 16px);
      background: rgba(0, 36, 149, 0.8);
      transition: 0.3s;
      opacity: 0;
    }*/

    &-bg {
      position: absolute;
      inset: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-wrapper {
      position: relative;

      padding: 20px;
      padding-left: 40px;
      min-height: 100%;
      z-index: 5;

      &:before {
        content: '';
        position: absolute;
        inset: 0;
        opacity: 80%;

        background: white;
        z-index: -1;
        border-radius: ac(24px, 16px);
        height: 100%;
        min-height: 100%;

        transition: 0.3s;
      }

      &:after {
        content: '';
        position: absolute;
        inset: 0;
        opacity: 0;
        background: rgba(0, 36, 149, 0.8);
        z-index: -1;
        border: ac(5px, 4px) solid var(--cl-pink);
        height: 100%;
        border-radius: ac(24px, 16px);
        min-height: 100%;

        transition: 0.3s;
      }
    }

    &-title {
      color: var(--cl-primary);
      font-weight: 700;
      font-size: ac(36px, 24px);
      line-height: 47.52px;
      letter-spacing: 0.1px;
      z-index: 15;

      transition: color 0.3s;

      width: 100%;
    }

    &-subtitle {
      color: var(--cl-primary);
      font-weight: 600;
      font-size: ac(22px, 16px);
      line-height: 32.56px;
      letter-spacing: 0.1px;

      padding-top: ac(24px, 18px);

      transition: color 0.3s;
    }

    &-description {
      /*max-height: 0;
      overflow: hidden;
      opacity: 0;*/
      color: var(--cl-primary);
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.1px;
      transition: 0.3s;
      padding-top: ac(24px, 18px);
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 20px;
      right: 20px;

      width: 64px;
      height: 64px;

      border-radius: 50%;

      transform: rotate(-45deg);
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background: var(--cl-pink);
        transform: rotate(45deg);

        i {
          color: var(--cl-white);
          transform: scale(1.1);
        }
      }

      i {
        color: var(--cl-pink);
      }
    }

    &:hover {
      @media (min-width: 1025px) {
        &:after {
          opacity: 1;
        }

        .global-section__item-wrapper {
          &:before {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }

        .global-section__item-title,
        .global-section__item-subtitle,
        .global-section__item-description {
          color: var(--cl-white);
        }

        .global-section__item-description {
          /*max-height: 600px;*/
          opacity: 1;
          padding-top: ac(24px, 18px);
        }

        .global-section__item-button {
          background: var(--cl-pink);
          transform: rotate(45deg);

          i {
            color: var(--cl-white);
            transform: scale(1.1);
          }
        }
      }
    }

    &.hovered {
      @media (max-width: 1024px) {
        @media (min-width: 769px) {
          &:after {
            opacity: 1;
          }

          .global-section__item-wrapper {
            &:before {
              opacity: 0;
            }

            &:after {
              opacity: 1;
            }
          }

          .global-section__item-title,
          .global-section__item-subtitle,
          .global-section__item-description {
            color: var(--cl-white);
          }

          .global-section__item-description {
            /*max-height: 600px;*/
            opacity: 1;
            padding-top: ac(24px, 18px);
          }

          .global-section__item-button {
            background: var(--cl-pink);
            transform: rotate(45deg);

            i {
              color: var(--cl-white);
              transform: scale(1.1);
            }
          }
        }
      }
    }

    &.swiper-slide-active {
      @media (max-width: 768px) {
        &:after {
          opacity: 1;
        }

        .global-section__item-wrapper {
          &:before {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }

        .global-section__item-title,
        .global-section__item-subtitle,
        .global-section__item-description {
          color: var(--cl-white);
        }

        .global-section__item-description {
          /*max-height: 600px;*/
          opacity: 1;
          padding-top: ac(24px, 18px);
        }

        .global-section__item-button {
          background: var(--cl-pink);
          transform: rotate(45deg);

          i {
            color: var(--cl-white);
            transform: scale(1.1);
          }
        }
      }
    }

    @media (max-width: 768px) {
      min-height: 350px;
    }
  }

  &__slider {
    .swiper {
      overflow: visible;
    }

    @media (min-width: 769px) {
      .swiper-buttons {
        position: absolute;
        top: 0;
        right: 0;

        .swiper-button {
          width: 45px;
          height: 45px;
        }
      }
    }

    @media (max-width: 768px) {
      .swiper-buttons {
        .swiper-button {
          margin-top: 24px;
        }
      }
    }
  }
}

.global-section__item-wrapper.hovered:before {
  background: var(--cl-primary);
  opacity: 80%;
}
