.jobs-section {
  padding-top: ac(120px, 40px);
  padding-bottom: ac(120px, 40px);
  position: relative;
  z-index: 10;
  background-color: var(--cl-blue-grey);

  @media (min-width: 1700px) {
    padding-top: 160px;
  }

  @media (min-width: 1950px) {
    padding-top: 200px;
  }

  .line-decor {
    top: ac(-60px, -30px);
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__title {
    color: var(--cl-blue);

    @media (min-width: 1201px) {
      max-width: calc(100% - 400px);
    }

    &:not(:last-child) {
      margin-bottom: ac(80px, 40px);
    }
  }

  &__slider {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .swiper {
      max-width: ac(846px, 768px);
      overflow: visible;

      @media (max-width: 768px) {
        max-width: 350px;
      }
    }
  }

  &__buttons {
    grid-column-gap: ac(24px, 20px);

    @media(min-width: 1201px) {
      position: absolute;
      top: ac(-91px, -45px);
      transform: translateY(-100%);
      right: 0;
    }

    @media (max-width: 1200px) {
      padding-top: 40px;
      flex-direction: column-reverse;
      align-items: center;
      grid-row-gap: 30px;

      .btn {
        min-width: 190px;
      }
    }
  }

  @media (max-width: 768px) {
    .jobs-section {
      &__title {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
