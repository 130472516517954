.job-card {
  --bottom-height: ac(90px, 70px);
  position: relative;
  z-index: 5;
  padding: ac(30px, 24px) ac(30px, 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  min-height: ac(243px, 200px);

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: var(--cl-white);
    height: calc(100% - var(--bottom-height));
    border-radius: 16px 16px 0 0;
    border: 4px solid var(--cl-blue-grey);
    border-bottom: none;
    content: '';
    z-index: -1;
  }

  &__bottom {
    aspect-ratio: 417 / 104;
    background-image: url('../../assets/static/mask/job-card-bottom.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    z-index: -2;
    transform: translateZ(0);
  }

  &__title {
    color: var(--cl-blue);
    transition: color 0.3s ease;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__list {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(20px, 18px);

    li {
      font-size: ac(24px, 18px);
      font-weight: 600;
      line-height: 1.1;
      transition: color 0.3s ease;
      color: var(--cl-blue);
    }
  }

  &__link {
    position: absolute;
    bottom: ac(8px, 6px);
    right: ac(8px, 6px);
    --size: ac(46px, 40px);
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(19px, 18px);
    color: var(--cl-white);
    cursor: pointer;
    background: var(--cl-pink);
    transition: background-color 0.3s ease;
    z-index: 10;

    i {
      transform: rotate(-45deg);
      transition: transform 0.3s ease;
    }

    /*&:hover {
      background-color: var(--cl-pink);
    }*/

    @media (max-width: 360px) {
      --size: 34px;
      font-size: 16px;
    }
  }

  &:hover {
    .job-card {
      &__link {
        background-color: var(--cl-blue);

        i {
          transform: rotate(0);
        }
      }

      &__title {
        color: var(--cl-pink);
      }

      &__list {
        li {
          color: var(--cl-pink);
        }
      }
    }
  }
}
