.expertise-section {
  padding-top: ac(120px, 60px);
  padding-bottom: ac(120px, 60px);

  background: var(--cl-white);

  .line-decor {
    bottom: -10px;
    z-index: 5;

    &:last-child {
      bottom: -20px;
      z-index: 4;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: ac(56px, 28px);
  }

  &__title {
    color: var(--cl-primary);

    padding-bottom: ac(64px, 32px);

    span {
      color: var(--cl-pink);
    }
  }

  &__accordion {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    @media (max-width: 951px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 630px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 49.22%;
    grid-row-gap: 8px;

    .expertise-section__item-second {
      @media (min-width: 952px) {
        display: none;
      }
    }

    @media (max-width: 1200px) {
      width: 52%;

      @media (min-width: 952px) {
        .expertise-section__item-btn {
          --size: 38px;
          top: 0;
          right: 0;
        }
      }
    }

    @media (max-width: 951px) {
      width: 100%;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    padding-top: ac(32px, 28px);
    padding-left: ac(36px, 20px);
    padding-right: ac(36px, 20px);
    padding-bottom: ac(32px, 24px);
    --bg-height: 78px;
    /*filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.08))
      drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.12));*/
    cursor: pointer;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: calc(100% - var(--bg-height));
      background: var(--cl-white);
      border: 4px solid var(--cl-blue-grey);
      border-top: none;
      transition: opacity 0.3s;
      border-radius: 0 0 12px 12px;
      z-index: -1;
      content: '';
    }

    &:before {
      position: absolute;
      z-index: -2;
      inset: -20px;
      background-image: url('../../assets/static/accordion-item-shadow.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      content: '';
      opacity: 0;
      transition: 0.3s;

      @media (max-width: 490px) {
        display: none;
      }
    }

    + .expertise-section__item-second {
      max-height: 0;
      opacity: 0;
      transition: 0.4s;
      pointer-events: none;
      position: relative;
      top: auto;
      left: auto;
      margin-bottom: -8px;

      .expertise-section__item-title {
        display: none;
      }

      @media (min-width: 952px) {
        display: none;
      }
    }

    &.active {
      &:before {
        opacity: 1;
      }

      &:after {
        border-color: rgba(0, 36, 149, 0.2);
        border-width: 1px;
      }

      .expertise-section__item-btn {
        border-color: rgba(0, 36, 149, 0.3);
        background: var(--cl-white);
        i {
          color: var(--cl-blue);

          &.icon-plus {
            opacity: 0;
          }

          &.icon-minus {
            opacity: 0.5;
          }
        }

        @media (min-width: 952px) {
          opacity: 0;
          pointer-events: none;
        }
      }

      .expertise-section__item-top-bg {
        &:after {
          opacity: 0;
        }

        &:before {
          opacity: 1;
        }
      }

      + .expertise-section__item-second {
        opacity: 1;
        max-height: 1000px;
        pointer-events: auto;
        margin-bottom: 0;
      }
    }

    &:hover {
      &:not(.active) {
        &:before {
          opacity: 0.7;
        }
      }
    }
  }

  &__item-top-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 630 / 80;
    z-index: -2;

    &:after,
    &:before {
      position: absolute;
      inset: 0;
      transition: 0.3s;
      content: '';
    }

    &:after {
      background-image: url('../../assets/static/accordion-top-bg.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      opacity: 1;

      @media (max-width: 490px) {
        background-image: url('../../assets/static/accordion-top-bg-mob.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    &:before {
      background-image: url('../../assets/static/accordion-top-bg-active.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      opacity: 0;

      @media (max-width: 490px) {
        background-image: url('../../assets/static/accordion-top-bg-active-mob.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    @media (max-width: 490px) {
      aspect-ratio: 377 / 80;
    }
  }

  &__item-btn {
    --size: ac(46px, 40px);
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    border: 1px solid var(--cl-pink);
    background: var(--cl-pink);
    top: ac(8px, 4px);
    right: ac(8px, 4px);
    border-radius: 50%;
    transition: 0.3s;

    i {
      position: absolute;
      z-index: 1;
      transition: 0.3s;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 26px;
      color: var(--cl-white);

      &.icon-minus {
        opacity: 0;
      }

      &.icon-plus {
        opacity: 1;
      }
    }

    &:hover {
      background-color: var(--cl-blue-grey);

      i {
        color: var(--cl-pink);
      }
    }
  }

  &__item-title {
    width: 100%;
    max-width: calc(100% - ac(80px, 64px));
    color: var(--cl-primary);

    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }

  &__item-text {
    color: var(--cl-black);

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__item-second {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: ac(24px, 12px);
    border: 1px solid var(--cl-blue-grey);
    overflow: hidden;
  }

  &__item-image {
    --arw: 594;
    --arh: 297;
    width: 100%;

    &:after {
      --overlay-left: 0px;
    }
  }

  &__item-content {
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(32px, 24px) ac(40px, 20px) ac(40px, 24px);

    .expertise-section__item-title {
      color: var(--cl-white);
    }

    .expertise-section__item-text {
      color: var(--cl-white);
    }
  }

  &__item-content-bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__desk-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 46.41%;
    position: relative;

    @media (min-width: 952px) {
      position: sticky;
      top: 110px;
      right: 0;
    }

    .expertise-section__item-second {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s;

      &.active {
        opacity: 1;
        position: relative;
        top: auto;
        left: auto;
        pointer-events: auto;
      }
    }

    @media (max-width: 951px) {
      display: none;
    }
  }
}
