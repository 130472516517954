:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }

  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    color: var(--pp-dark-blue);
    font-size: ac(24px, 20px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(25px, 15px);
    top: ac(25px, 15px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--pp-dark-blue);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;
      transform: translateX(10px);

      .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @media (max-width: 640px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }

  &__block {
    &.project-popup {
      padding: ac(48px, 24px) ac(48px, 20px);
      background: var(--cl-white);
      max-width: ac(790px, 600px);
      width: 95%;

      border: 1px solid rgba(5, 5, 5, 0.15);
      border-radius: ac(24px, 16px);

      .popup {
        &__content {
          width: calc(100% + 12xp);
          padding-right: 12px;

          .simplebar-track.simplebar-vertical {
            width: 3px;
            background: #b0d8ce;
            border-radius: 4px;
            transform: none;
            top: ac(86px, 40px);
            bottom: 0;
            overflow: visible;

            .simplebar-scrollbar {
              background: var(--cl-pink);
              border-radius: 4px;
              width: 6px;
              left: -1.5px;

              &:before {
                content: none;
              }
            }
          }
        }

        &__title {
          font-size: ac(48px, 24px);
          font-weight: 700;
          line-height: 1.12;
          letter-spacing: 0.02em;
          color: var(--cl-primary);
          font-family: var(--font-second);
          text-transform: none;

          &:not(:last-child) {
            margin-bottom: ac(32px, 20px);
          }
        }

        &__btn-close {
          position: absolute;
          top: ac(24px, 14px);
          right: ac(28px, 14px);
          display: flex;
          justify-content: center;
          align-items: center;
          --size: ac(24px, 28px);
          width: var(--size);
          height: var(--size);
          cursor: pointer;
          z-index: 10;

          i {
            color: var(--cl-primary);
            font-size: ac(32px, 28px);
            transition: 0.3s;
          }

          &:hover {
            i {
              color: var(--cl-pink);
              transition: 0.3s;
            }
          }
        }
      }

      &:not(.team-popup) {
        .popup {
          &__content {
            .simplebar-track.simplebar-vertical {
              top: 0;
            }
          }
        }
      }

      .popup-form {
        &__container {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-column-gap: ac(16px, 14px);
          grid-row-gap: 12px;

          @media (max-width: 551px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }

          .meta-input,
          .meta-upload-file {
            label {
              color: var(--cl-primary);

              span {
                color: var(--cl-pink);
              }
            }
          }

          .meta-input {
            input,
            textarea {
              border-color: var(--cl-primary);
              color: var(--cl-primary);
              border-radius: 0;
              clip-path: inset(0 0 0 0 round 0);

              &::placeholder {
                color: var(--cl-primary);
                opacity: 0.7;
              }
            }
          }
        }

        &__bottom {
          padding-top: ac(32px, 20px);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 16px;
          padding-bottom: 2px;

          .meta-checkbox {
            max-width: calc(100% - 192px);

            .meta-checkbox__text {
              font-size: ac(18px, 16px);
            }
          }

          .btn {
            min-width: 192px;
          }

          @media (max-width: 551px) {
            flex-direction: column;

            .meta-checkbox {
              max-width: 100%;
              width: 100%;
            }

            .btn {
              width: 100%;
            }
          }
        }
      }
    }

    &.team-popup {
      padding: 0;
      background: transparent;
      max-width: ac(1014px, 900px);
      width: 95%;
      border-radius: ac(16px, 10px);
      border: 1px solid rgba(5, 5, 5, 0.1);
      overflow: hidden;

      .team-popup {
        &__container {
          width: 100%;
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
        }

        &__content-container-decor {
          --arw: 411.2;
          --arh: 448.96;
          position: absolute;
          bottom: ac(-102px, -40px);
          right: ac(-111px, -40px);
          width: ac(411px, 190px);
          mix-blend-mode: soft-light;
        }

        &__card {
          width: ac(305px, 240px);
          min-width: ac(305px, 240px);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          background: transparent;

          @media (max-width: 640px) {
            display: none;
          }
        }

        &__card-image-container {
          width: 100%;
          overflow: hidden;
          position: relative;
          background: var(--cl-primary);
          overflow: hidden;
          border: 1px solid rgba(0, 36, 149, 0.2);
          border-radius: 12px 12px 0px 12px;
        }

        &__card-image-decor {
          --arw: 1;
          --arh: 1;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
        }

        &__card-image {
          --arw: 1;
          --arh: 1;
          width: 100%;
          position: relative;
          z-index: 1;

          img {
            object-fit: contain;
            object-position: bottom;
          }
        }

        &__card-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding: ac(20px, 16px) ac(32px, 20px) ac(20px, 18px);
          background: white;
          flex: 1;
          border: 1px solid rgba(0, 36, 149, 0.2);
          border-radius: 12px 0px 12px 12px;
        }

        &__card-title {
          font-family: var(--font-second);
          font-size: ac(30px, 22px);
          font-weight: 700;
          line-height: 1.28;
          letter-spacing: 0.3px;
          color: var(--cl-primary);

          &:not(:last-child) {
            margin-bottom: ac(16px, 12px);
          }
        }

        &__card-subtitle {
          font-size: ac(18px, 16px);
          font-weight: 500;
          line-height: 1.4;
          letter-spacing: 0.5px;
          color: var(--cl-pink);

          &:not(:last-child) {
            margin-bottom: ac(16px, 12px);
          }
        }

        &__card-socials {
          margin-top: auto;
          grid-column-gap: ac(16px, 12px);
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        &__card-social {
          --size: 40px;
          width: var(--size);
          height: var(--size);
          font-size: 34px;
          color: var(--cl-primary);
          transition: 0.3s;
          display: flex;
          justify-content: center;
          align-items: center;
          i.icon-mail-author {
            font-size: 44px;
          }
          &:hover {
            color: var(--cl-pink);
            transform: scale(1.2);
          }
        }

        &__content-container {
          width: calc(100% - ac(305px, 240px));
          position: relative;
          background: var(--cl-white);
          padding-right: ac(80px, 40px);
          padding-top: ac(40px, 24px);
          padding-left: ac(40px, 20px);
          padding-bottom: ac(96px, 80px);
          border: 1px solid #ccd3ea;
          border-radius: 12px;
          overflow: hidden;

          @media (min-width: 641px) {
            .team-popup__card-image-container {
              display: none;
            }

            .team-popup__card-content {
              display: none;
            }
          }

          @media (max-width: 640px) {
            width: 100%;
            padding-bottom: 0;

            .team-popup__card-image-container {
              max-width: 390px;
              width: calc(100% - 24px);
              background: transparent;
              border-radius: 12px;
            }

            .team-popup__card-content {
              border: none;
              padding-left: 0;
              padding-right: 0;
              background: transparent;
            }
          }
        }

        &__content {
          width: calc(100% + ac(35px, 21px));
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding-right: ac(35px, 21px);
          overflow: visible;

          .simplebar-track.simplebar-vertical {
            background: #edf0f8;
            transform: none;
            top: ac(72px, 60px);
            bottom: ac(40px, 20px);
            border-radius: 6px;
            width: 6px;
            overflow: visible;

            .simplebar-scrollbar {
              background: var(--cl-pink);
              border-radius: 6px;
              width: 6px;

              &:before {
                content: none;
              }
            }
          }

          @media (min-width: 641px) {
            max-height: 45.11vh;
          }
        }

        &__content-bottom {
          padding: 10px ac(40px, 20px);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          grid-column-gap: ac(32px, 16px);
          grid-row-gap: 10px;
          background: var(--cl-white);
          border-top: 1px solid #ccd3ea;
          .btn {
            padding: 11px ac(40px, 24px);
            .btn__text {
              font-size: 18px;
            }
          }
          .link-btn {
            .link-btn__text {
              font-size: 18px;
              color: rgba(10, 10, 10, 0.8);
            }
          }
          @media (min-width: 641px) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            min-height: ac(96px, 80px);
          }

          @media (max-width: 640px) {
            padding-top: 20px;
            padding-bottom: 16px;
            border-top: none;
            padding-left: 0;
            padding-right: 0;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            .btn {
              width: auto;
            }
          }
        }

        &__title {
          font-family: var(--font-second);
          font-size: ac(40px, 24px);
          font-weight: 700;
          line-height: 1.32;
          letter-spacing: 0.04em;
          color: var(--cl-primary);

          span {
            color: var(--cl-pink);
          }

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }

        &__text {
          width: 100%;
          font-size: ac(18px, 16px);
          font-weight: 400;
          line-height: 1.5;
          color: var(--cl-black);

          p,
          li {
            font-size: ac(18px, 16px);
            font-weight: 400;
            line-height: 1.5;
            color: var(--cl-black);
          }

          p,
          ul,
          ol {
            &:not(:last-child) {
              margin-bottom: ac(20px, 14px);
            }

            &:last-child {
              padding-bottom: ac(24px, 20px);
            }
          }
        }

        &__btn-close {
          position: absolute;
          top: ac(20px, 16px);
          right: ac(20px, 16px);
          display: flex;
          justify-content: center;
          align-items: center;
          --size: ac(56px, 32px);
          width: var(--size);
          height: var(--size);
          cursor: pointer;
          z-index: 10;
          border-radius: 50%;
          border: 1px solid var(--cl-pink);
          background: var(--cl-blue-grey);

          i {
            color: var(--cl-pink);
            font-size: 16px;
            transition: 0.3s;
          }

          &:hover {
            background: var(--cl-white);

            i {
              color: var(--cl-primary);
              transition: 0.3s;
            }
          }
        }
      }
    }
  }
}
#apply_form {
  input {
    border-radius: ac(39px, 28px);
  }
  input,
  textarea {
    border: 1px solid var(--cl-black);
    color: var(--cl-black);
    &::placeholder {
      color: rgba(10, 10, 10, 0.7);
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}
