.blogs-section {
  padding-top: ac(120px, 40px);
  padding-bottom: ac(120px, 50px);
  position: relative;
  z-index: 10;

  @media (min-width: 1700px) {
    padding-top: 160px;
  }

  .line-decor {
    &:nth-child(1) {
      top: ac(-24px, -12px);
    }
    &:nth-child(2) {
      top: ac(-60px, -20px);
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__title {
    color: var(--cl-blue);

    @media (min-width: 1201px) {
      max-width: calc(100% - 400px);
    }

    &:not(:last-child) {
      margin-bottom: ac(64px, 40px);
    }
  }

  &__slider {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .swiper {
      overflow: visible;
    }
  }

  &__buttons {
    grid-column-gap: ac(24px, 20px);

    @media (min-width: 1201px) {
      position: absolute;
      top: ac(-64px, -40px);
      transform: translateY(-100%);
      right: 0;

      .swiper-buttons {
        margin-right: ac(-24px, -20px);
      }
    }

    @media (max-width: 1200px) {
      padding-top: 40px;
      flex-direction: column-reverse;
      align-items: center;
      grid-row-gap: 30px;

      .btn {
        min-width: 190px;
      }
    }
  }

  &.post-roles {
    &.blogs-section {
      padding-bottom: ac(100px, 50px);
    }
  }

  &.post-roles {
    &.blogs-section {
      padding-bottom: ac(100px, 50px);
    }
  }

  &.blogs-page {
    .blogs-section__list {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}
