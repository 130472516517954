.job-search-first {
  position: relative;
  padding-top: ac(194px, 118px);
  padding-bottom: ac(224px, 140px);
  .cont {
    position: relative;
    z-index: 21;
  }
  &__bg-image {
    position: absolute;
    inset: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .line-decor {
    position: absolute;
    bottom: ac(-34px, -9px, 320);
  }
  &__title {
    color: var(--cl-white);
    margin-bottom: ac(48px, 30px);
    span {
      color: var(--cl-pink);
    }
  }

  &-form {
    display: flex;
    flex-wrap: wrap;
    column-gap: ac(20px, 12px);
    row-gap: ac(32px, 20px);
    max-width: ac(864px, 790px);
    @media (max-width: 690px) {
      flex-direction: column;
    }
    &__keyword {
      position: relative;
      width: 100%;
      max-width: ac(420px, 310px);
      @media (max-width: 820px) {
        max-width: ac(351px, 296px, 690, 820);
      }
      @media (max-width: 690px) {
        max-width: 768px;
      }
      input {
        padding-left: ac(51px, 44px);
      }
      span {
        position: absolute;
        left: ac(16px, 12px);
        top: 50%;
        transform: translateY(-50%);
        color: var(--cl-white);
        font-size: ac(27px, 20px);
      }

      + .meta-select {
        max-width: ac(419px, 380px)!important;
      }
    }

    .meta-select {
      max-width: ac(318px, 274px, 820);
      width: 100%;
      @media (max-width: 820px) {
        max-width: 48.7%!important;
      }
      @media (max-width: 690px) {
        max-width: 768px!important;
      }
    }

    .btn {
      display: flex;
      align-items: center;
      gap: ac(12px, 10px);
      background: var(--cl-pink);
      padding: ac(12px, 10px) ac(34px, 20px);
      .btn__text {
        font-size: ac(22px, 18px);
        line-height: 1.4;
        color: #FFF;
      }
      .btn__icon {
        display: flex;
      }
      i {
        color: #FFF;
        font-size: ac(20px, 20px);
      }
    }
  }

  &.blogs-page {

    &.job-search-first {
      padding-bottom: ac(312px, 250px);
    }

    .line-decor {
      bottom: 120px;
    }
  }
}