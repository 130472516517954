.footer {
  padding-top: 0;
  padding-bottom: 40px;
  position: relative;
  overflow: visible;
  z-index: 30;

  &:after {
    background: var(--cl-blue-grey);
    bottom: 0;
    left: 0;
    right: 0;
    height: 56%;
    content: '';
    position: absolute;
  }

  &__wrap {
    position: relative;
    z-index: 1;
  }

  @media (min-width: 1700px) {
    padding-top: 30px;
  }

  @media (min-width: 2100px) {
    padding-top: 60px;
  }

  .line-decor {
    top: ac(-68px, -20px);
  }

  &__container {
    position: relative;
    z-index: 10;
    padding: ac(78px, 40px) ac(64px, 20px) ac(80px, 64px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 1150px) {
      padding-left: ac(30px, 20px);
      padding-right: ac(30px, 20px);
    }

    @media (max-width: 1024px) {
      padding-bottom: 30px;
    }
  }

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: 24px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__nav {
    width: 100%;
    padding-bottom: ac(40px, 30px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    &:not(:last-child) {
      margin-bottom: ac(40px, 30px);
    }

    @media (max-width: 1023px) {
      flex-direction: column;
      grid-row-gap: 30px;
    }
  }

  &__nav-list {
    width: calc(100% - 200px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(34px, 16px);

    a {
      font-size: ac(20px, 18px);
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      position: relative;
      color: var(--cl-white);

      &:before {
        position: absolute;
        bottom: -2px;
        height: 4px;
        background: var(--cl-white);
        right: 0;
        width: 0;
        opacity: 0;
        transition: width 0.3s ease, opacity 0.3s ease,
          background-color 0.3s ease;
        content: '';
      }

      &:hover {
        &:before {
          right: auto;
          left: 0;
          width: 100%;
          opacity: 1;
          background-color: var(--cl-pink);
        }
      }
    }

    @media (max-width: 1279px) {
      grid-column-gap: 20px;

      a {
        font-size: 18px;
      }
    }

    @media (max-width: 1150px) {
      a {
        font-size: 16px;
      }
    }

    @media (max-width: 1023px) {
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
      grid-row-gap: 12px;

      a {
        font-size: 18px;
      }
    }

    @media (max-width: 551px) {
      a {
        font-size: 16px;
      }
    }
  }

  &__nav-socials {
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
      grid-row-gap: 30px;
    }
  }

  &__logo {
    --arw: 207;
    --arh: 56;
    width: 18.17%;
    cursor: pointer;
    transition: transform 0.25s linear;

    img {
      object-fit: contain;
    }

    &:hover {
      transform: scale(0.95);
    }

    @media (max-width: 1023px) {
      width: 190px;
    }
  }

  &__content-grid {
    width: 71.31%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(30px, 20px);

    @media (min-width: 1430px) {
      grid-template-columns: 191px repeat(1, minmax(0, 1fr)) 272px;
      grid-column-gap: 30px;
    }

    @media (max-width: 1279px) {
      width: 75%;
      grid-column-gap: 30px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      .footer {
        &__content-item {
          &:last-child {
            margin-top: auto;
          }
        }
      }
    }

    @media (max-width: 1023px) {
      width: 100%;
      justify-content: center;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      grid-row-gap: 20px;

      .footer {
        &__content-item {
          &:last-child {
            width: 100%;
            align-items: center;
          }
        }

        &__content-links {
          justify-content: center;
        }
      }
    }
  }

  &__content-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:last-child {
      @media (min-width: 1025px) {
        padding-top: ac(42px, 30px);
      }
    }

    @media (max-width: 551px) {
      width: 100%;
      align-items: center;
    }
  }

  &__content-title {
    font-size: ac(18px, 16px);
    font-weight: 700;
    line-height: 166.67%;
    color: var(--cl-pink);
  }

  &__content-link {
    font-size: ac(18px, 16px);
    font-weight: 400;
    line-height: 166.67%;
    position: relative;
    transition: color 0.3s ease;
    cursor: pointer;
    color: var(--cl-white);

    span.word {
      position: relative;
      z-index: 1;

      &:after {
        position: absolute;
        inset: 0 -2.2px;
        background: var(--cl-pink);
        content: '';
        z-index: -1;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }

    i {
      margin-top: auto;
      margin-bottom: auto;
      font-size: ac(24px, 22px);
      margin-right: ac(10px, 8px);
      transition: color 0.3s ease, transform 0.3s ease;
    }

    &.with-icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &:hover {
      color: var(--cl-white);

      span.word {
        &:after {
          opacity: 1 !important;
        }
      }

      &.with-icon {
        i {
          color: var(--cl-pink);
          transform: scale(1.2);
        }
      }
    }

    @media (max-width: 551px) {
      text-align: center;

      &.with-icon {
        justify-content: center;
      }
    }
  }

  &__content-links {
    width: 100%;
    padding-top: ac(24px, 12px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: ac(20px, 12px);
    grid-row-gap: 10px;
  }

  &__developer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    transition: all 0.3s ease;
    margin-left: auto;
    margin-right: auto;
    transform-origin: center;

    img {
      width: 100%;
    }

    &:hover {
      transform: scale(1.05);
    }

    @media (min-width: 1025px) {
      position: absolute;
      bottom: ac(40px, 20px);
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;

      &:hover {
        transform: scale(1.05) translateX(-50%);
      }
    }

    @media (max-width: 1024px) {
      margin-top: 30px;
    }
  }
}
