.our-team {
  padding-top: ac(80px, 40px);
  padding-bottom: ac(120px, 64px);
  background: var(--cl-blue-grey);

  @media (min-width: 1900px) {
    padding-top: 120px;
  }

  .line-decor {
    top: -117px;
    bottom: auto;

    @media (min-width: 1800px) {
      top: -150px;
    }

    @media (min-width: 2200px) {
      top: -180px;
    }
  }

  &__content {
    padding-bottom: ac(64px, 24px);
  }

  &__slider {
    .swiper-buttons {
      .swiper-button {
        margin-top: ac(32px, 30px);
      }
    }

    .swiper {
      overflow: visible;

      &:not(.swiper-initialized) {
        .swiper-wrapper {
          display: flex;
          align-items: stretch;
          justify-content: center;
          flex-wrap: wrap;
          grid-column-gap: 1.56%;
          grid-row-gap: 20px;
          max-width: 1100px;
          margin-left: auto;
          margin-right: auto;

          .swiper-slide {
            width: calc(33% - 1.56%);

            @media (max-width: 1270px) {
              width: calc(33% - 1.56%);
            }
          }
        }

        + .swiper-buttons {
          display: none;
        }
      }

      @media (max-width: 500px) {
        max-width: 290px;
        margin-left: 0;
      }
    }
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    --bottom-height: ac(79px, 70px);
    width: 100%;
    cursor: pointer;
    &:hover {
      .our-team__card-link {
        transform: rotate(0deg);
        background: var(--cl-primary);

        i {
          color: var(--cl-white);
        }
      }
    }
    &-top {
      position: relative;
      width: 100%;
      border-radius: 12px;
      overflow: hidden;

      &__bg {
        position: absolute;

        inset: 0;

        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__image {
        position: relative;
        width: 100%;
        z-index: 2;
        --arw: 1;
        --arh: 1;
      }
    }

    &-bottom {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      min-height: 252px;
      padding: ac(20px, 14px) 32px;
      z-index: 6;
      overflow: hidden;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: var(--cl-white);
        height: calc(101% - var(--bottom-height));
        border-radius: 12px 12px 0 0;
        border: 1px solid rgba(0, 36, 149, 0.16);
        border-bottom: none;
        content: '';
      }

      &-subtract {
        --arw: 305;
        --arh: 79.02;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        overflow: visible;
        mask-image: none;
        img {
          object-position: bottom;
        }
      }
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 15;

      width: 54px;
      height: 54px;

      border-radius: 50%;
      border: 1px solid #00249529;
      background: var(--cl-white);

      transform: rotate(45deg);
      transition: all 0.3s;

      &:hover {
        transform: rotate(0deg);
        background: var(--cl-primary);

        i {
          color: var(--cl-white);
        }
      }

      i {
        color: #0b2fa0;
      }
    }
  }

  &__title {
    color: var(--cl-primary);
    text-align: center;

    span {
      color: var(--cl-pink);
    }

    &.heading-3 {
      font-size: ac(64px, 32px);
      line-height: normal;
    }
  }

  &__name-list {
    display: flex;
    flex-direction: column;

    gap: ac(16px, 8px);

    z-index: 2;

    li:first-child {
      display: flex;
      flex-direction: column;
      font-weight: 700;
      font-size: ac(30px, 22px);
      line-height: normal;
      color: var(--cl-primary);
    }

    li:last-child {
      font-weight: 500;
      font-size: ac(18px, 17px);
      line-height: normal;

      color: var(--cl-pink);
    }
  }

  &__social-list {
    display: flex;
    align-items: center;
    gap: ac(16px, 8px);

    z-index: 2;

    a {
      width: 40px;
      height: 40px;

      transition: all 0.3s;

      &:hover {
        transform: scale(1.2);

        svg path {
          fill: var(--cl-pink);
        }
      }
    }
  }

  .hire-section__buttons {
    .swiper-button {
      border: 2px solid var(--cl-navy);
      border-radius: 50%;
      --size: ac(62px, 55px);
      width: var(--size);
      height: var(--size);
      background: transparent;

      &:before {
        color: var(--cl-navy);
      }

      &:hover {
        &:before {
          color: var(--cl-white);
        }
      }
    }
  }
}
