.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-primary) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-white);

  color: var(--cl-black);
  font-family: var(--font-main);
  font-size: ac(20px, 16px);
  font-weight: 400;
  line-height: 1.6;

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  flex-direction: column;
  align-items: stretch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-grey);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-primary);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &:not(.is-visible) {
    overflow: hidden;
  }

  &.is-visible {
    @media (max-width: 640px) {
      overflow: hidden;
    }
  }
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1280px;
  width: perc(1280);

  @media (max-width: 1024px) {
    width: 89.33%;
  }
}

section,
footer,
header {
  position: relative;
  width: 100%;
  max-width: 100vw;
}

.swiper {
  width: 100%;
  max-width: 100%;
}

.sections-container {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
}

.title-animation {
  opacity: 0;

  &.animated {
    opacity: 1;

    .splitting .word {
      animation: slide-in 0.5s cubic-bezier(0.5, 0, 0.5, 1) both;
      animation-delay: calc(100ms * var(--word-index));
      /*clip-path: polygon(110% 0, 110% 100%, 0 100%, 0 0);*/
    }
  }
}

.second-title-animation {
  transition: all 0.3s ease;
  opacity: 0;
  transform: scaleX(1.1) translateX(5%);
  transform-origin: top left;

  &.animated {
    opacity: 1;
    transform: scaleX(1) translateX(0);
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-0.5em) scaleY(1.2) rotate(0);
    opacity: 0;
    /*clip-path: polygon(110% 80%, 110% 100%, 0 100%, 0 80%);*/
  }
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }
}

[data-scroll] {
  width: calc(100% + 10px);
  padding-right: 10px;

  .os-scrollbar-track {
    --os-track-bg: var(--cl-grey);
  }

  .os-theme-dark,
  .os-theme-light {
    --os-size: ac(8px, 6px);
    --os-track-border-radius: 6px;
  }

  .os-theme-dark {
    --os-handle-bg: var(--cl-blue);
    --os-handle-bg-hover: var(--cl-pink);
  }
}

.image-aspect-ratio {
  position: relative;
  overflow: hidden;
  mask-image: radial-gradient(white, black);

  &::before {
    content: '';
    display: block;
    padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
  }

  img,
  svg,
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
  }
}

.overlay-image-bg {
  position: relative;
  overflow: hidden;

  &:before {
    background: var(--cl-white);
    z-index: 2;
    opacity: 0.16;
    position: relative;
  }

  &:after {
    --overlay-left: 80px;
    aspect-ratio: 1 / 1;
    background-image: url('../../assets/static/bg-overlay.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + (var(--overlay-left) * 2));
    z-index: 3;
    content: '';
    opacity: 0.7;
    mix-blend-mode: color;
  }
}

.max-line-length-number {
  padding: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-line-num);
  -webkit-box-orient: vertical;
  overflow: hidden;
}
