.stats-section {
  padding-top: ac(164px, 40px);
  padding-bottom: ac(120px, 45px);
  position: relative;
  z-index: 5;
  overflow: hidden;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__title-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: ac(80px, 40px);
    }
  }

  &__title {
    width: 47.66%;
    color: var(--cl-blue);
    span {
      &:last-child {
        &:not(.word) {
          color: var(--cl-pink);
        }
      }
    }
  }

  &__content {
    width: 40.78%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    .swiper-button {
      margin-top: ac(56px, 30px);
    }

    @media (max-width: 550px) {
      .swiper {
        overflow: visible;
        max-width: 280px;
      }
    }
  }

  &__item {
    border-radius: 24px;
    border: 4px solid var(--cl-blue-grey);
    padding: ac(38px, 24px) 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__item-count {
    color: var(--cl-pink);
    font-family: var(--font-second);
    font-size: ac(80px, 40px);
    line-height: 1;
    font-weight: 600;

    .count-up {
      color: var(--cl-blue);
      font-weight: 700;
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__item-text {
    width: 100%;
    text-align: center;
    color: var(--cl-blue);
    letter-spacing: 0;
    font-weight: 500;
  }

  @media (max-width: 901px) {
    .stats-section {
      &__title-content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        grid-row-gap: 30px;
      }

      &__title {
        width: 100%;
      }

      &__content {
        width: 100%;
      }
    }
  }
}
