.job-description {
  padding-top: ac(80px, 40px);
  padding-bottom: ac(120px, 60px);

  &__content {
    font-weight: 400;
    font-size: ac(22px, 16px);
    line-height: 32.56px;

    &-title {
      color: var(--cl-primary);

      span {
        color: var(--cl-pink);
      }

      &.heading-4 {
        font-weight: 700;
        font-size: ac(50px, 32px);
        line-height: normal;
        letter-spacing: 0.5px;
      }

      &.heading-6 {
        font-weight: 700;
        font-size: ac(30px, 18px);
        line-height: 38.4px;
        letter-spacing: 0.2px;
      }
    }

    p {
      span {
        font-weight: 500;
        color: var(--cl-primary);
      }
    }

    &-list {
      li::marker {
        color: var(--cl-pink);
      }
    }
  }
}
