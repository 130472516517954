.job-search-section {
  padding-top: ac(64px, 40px);
  padding-bottom: ac(120px, 40px);
  overflow: visible;
  position: relative;
  z-index: 31;

  &__subtitle {
    width: 100%;
    text-align: center;
    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }

    @media (max-width: 640px) {
      br {
        display: none;
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    flex-direction: row-reverse;
  }

  &__sidebar {
    flex: 1;
    max-width: 23.83%;
    /* margin-top: ac(64px, 32px); */
    /* TODO */
    @media (max-width: 1300px) {
      width: 26%;
    }
  }
  &__sidebar-inner {
    .btn-close__icon {
      transition: 0.3s all;
    }
    .btn-close__icon:hover {
      opacity: 0.85;
    }
  }
  &__sidebar-filters-title {
    /* margin-top: 4px; */
    width: 100%;
    margin-bottom: ac(32px, 24px);
    position: relative;
    font-size: ac(28px, 20px);
    line-height: 1.4;
    font-weight: bold;
    color: #162c67;

    .btn-close {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 5;
      @media (min-width: 641px) {
        display: none !important;
      }
    }
  }

  &__sidebar-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: ac(32px, 24px);

    .btn-show-hide {
      margin-top: ac(12px, 10px);
    }
  }

  &__sidebar-item-content {
    /*padding-top: 6px;*/
    transition: max-height 0.4s ease, margin-top 0.3s ease, opacity 0.3s ease;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    /*grid-column-gap: 10px;
    grid-row-gap: 9px;*/
    overflow: hidden;

    &.show-hide-content {
    }

    &.with-inputs {
      grid-row-gap: ac(12px, 10px);
      overflow: visible;

      .meta-select,
      .meta-input {
        width: 100%;
      }
    }
  }

  &__sidebar-accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: 8px;
    transition: min-height 0.4s ease;
  }

  &__sidebar-accordion-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    transition: all 0.4s ease;
    &.active {
      .job-search-section__sidebar-accordion-item-top {
        margin-bottom: 9px;
      }

      .job-search-section__sidebar-accordion-item-btn {
        transform: scaleY(-1);
      }

      .job-search-section__sidebar-accordion-item-content {
        opacity: 1;
      }
    }

    &:not(.active) {
      cursor: pointer;

      .job-search-section__sidebar-accordion-item-content {
        max-height: 0 !important;
      }

      .job-search-section__sidebar-accordion-item-btn {
        transform: scaleY(1);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(23px, 18px);
    }
  }

  &__sidebar-accordion-item-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: padding-bottom 0.4s ease, margin 0.4s ease, opacity 0.3s ease;
    margin-bottom: 0;
    position: relative;
    padding-bottom: 14px;
    border-bottom: 1px solid #dbba9b;
    cursor: pointer;
    &:hover {
      opacity: 0.78;
    }
  }

  &__sidebar-accordion-item-title {
    max-width: calc(100% - 40px);
    color: var(--cl-blue);
    font-size: ac(24px, 20px);
    line-height: 132%;
    font-weight: 500;
  }

  &__sidebar-accordion-item-btn {
    --size: ac(36px, 34px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    transition: all 0.4s ease;
    position: relative;

    i {
      font-size: 16px;
      transition: all 0.4s ease;
      color: var(--cl-pink);
      pointer-events: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
    }

    &:hover {
      i {
        color: var(--cl-blue);
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  &__sidebar-accordion-item-content {
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /*max-height: 0;*/
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.4s ease;
    width: 100%;

    .meta-checkbox {
      min-height: ac(36px, 24px);
      &:hover {
        .meta-checkbox__box {
          border-color: var(--cl-pink);
        }
        .meta-checkbox__text {
          color: var(--cl-blue);
        }
      }
      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &.is-hidden {
        display: none;
      }
    }
  }

  &__sidebar-accordion-item-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(1px, 3px);
    width: 100%;

    .meta-checkbox__text {
      transform: translateY(2px);
      color: var(--cl-black);
      max-width: 233px;
    }
    .meta-checkbox__box {
      i.icon-check {
        color: var(--cl-white) !important;
      }
    }

    .meta-select {
      width: 100%;
      .choices {
        &__inner {
          background: var(--cl-white);
          border-color: var(--cl-blue);

          .choices__placeholder {
            color: var(--cl-charcoal) !important;
          }
        }

        &__list {
          background-color: var(--cl-white);
          &--single .choices__item {
            color: rgba(10, 10, 10, 0.7) !important;
          }
        }

        &:after {
          color: var(--cl-blue);
        }
      }

      &:not(:first-child) {
        margin-top: ac(9px, 11px);
      }
    }
  }

  &__sidebar-buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: ac(16px, 14px);

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;

      &:not(.btn-blue) {
        border-color: var(--cl-blue);

        .btn__text {
          color: var(--cl-blue);
        }

        &:hover {
          border-color: var(--cl-blue);
        }
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: ac(60px, 32px);
    .meta-select {
      width: 100%;
      max-width: ac(270px, 220px);
      display: none;
      @media (min-width: 640px) {
        display: block;
      }
      .choices {
        &__inner {
          background: var(--cl-white);
          border-color: var(--cl-blue);
        }

        &:after {
          color: var(--cl-blue);
        }

        &__list {
          background-color: var(--cl-white);
          &--single .choices__item {
            color: rgba(10, 10, 10, 0.7) !important;
          }
        }
      }
    }
  }
  &__result {
    width: 71.8%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    @media (max-width: 1300px) {
      width: 67%;
    }

    @media (max-width: 640px) {
      position: relative;
    }
  }

  &__title {
    font-size: ac(64px, 28px);
    font-weight: bold;
    color: var(--cl-blue);
    line-height: 1.2;
    font-family: var(--font-second);
  }

  &__subtitle {
    margin-top: ac(-4px, 0px);
    font-size: ac(24px, 20px);
    color: var(--cl-blue);
    line-height: 1.32;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__result-bg {
    display: none;
    @media (max-width: 640px) {
      display: block;
      position: absolute;
      inset: 0;
      z-index: 10;
      pointer-events: none;
    }
  }

  &__result-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: 20px;

    @media (max-width: 901px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .job-card {
      &__link {
        @media (max-width: 901px) {
          --size: ac(58px, 48px, 768, 901);
        }
        @media (max-width: 640px) {
          --size: ac(58px, 35px, 320, 640);
        }
      }
    }
  }

  &__filters-btn {
    @media (min-width: 641px) {
      display: none !important;
    }
  }

  @media (max-width: 1024px) {
    &__sidebar {
      max-width: 30%;
    }
  }

  @media (max-width: 768px) {
    &__sidebar {
      max-width: 40%;
    }

    &__result {
      width: 55%;
    }
  }

  @media (max-width: 640px) {
    &__container {
      flex-direction: row;
    }

    &__sidebar {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      opacity: 0;
      max-width: 280px;
      z-index: 2;
      transition: all 0.4s ease;

      @mixin media 374 {
        max-width: 100%;
      }
    }

    &__result {
      width: 100%;
      transition: all 0.3s ease;
      transform: translateX(0);
      opacity: 1;
      position: relative;
    }

    &__filters-btn {
      display: flex;
      &:hover {
        .btn__icons i.icon-filters {
          color: #fff;
        }
      }
    }

    &__container {
      &.active {
        .job-search-section__sidebar {
          position: relative;
          opacity: 1;
          transform: translateX(0);
        }

        .job-search-section__result {
          position: absolute;
          left: 0;
          top: 0;
          transform: translateX(305px);
          opacity: 0.4;

          & > * {
            pointer-events: none;
          }

          @mixin media 374 {
            transform: translateX(110%);
          }
        }

        .job-search-section__result-bg {
          pointer-events: auto;
        }
      }
    }
  }

  &.blogs-page {
    &.job-search-section {
      padding-top: ac(80px, 60px);
      padding-bottom: ac(100px, 50px);
    }
    .job-search-section__filters-btn {
      @media (max-width: 640px) {
        display: none;
      }
    }
    .job-search-section__nav {
      @media (max-width: 640px) {
        flex-wrap: wrap;
        gap: 18px;
      }
    }
    .job-search-section__nav .meta-select {
      @media (max-width: 640px) {
        display: flex;
      }
    }
    .job-search-section__result {
      width: 100%;
    }
    
    .sidebar {
      @media (min-width: 640px) {
        display: none;
      }
    }

    .meta-select__wrapper {
      display: flex;
      gap: 20px;

      @media (max-width: 640px) {
        /* display: none; */
      }
    }

    .job-search-section__result-grid {
      grid-template-columns: repeat(3, 1fr);

      @media (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 560px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

