.blog-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: ac(16px, 10px);
  height: auto;

  &__image {
    --arw: 413;
    --arh: 296;
    width: 100%;
    mask-image: url('../../assets/static/mask/blog-card-mask.svg');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;

    img {
      transition: all 0.3s ease;
      filter: grayscale(0);
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }

    &:after {
      position: absolute;
      inset: 0;
      z-index: 1;
      background: var(--cl-blue);
      content: '';
      opacity: 0;
      transition: 0.3s;
      mix-blend-mode: screen;
    }
  }

  &__subtitle {
    font-size: ac(18px, 16px);
    line-height: 122.22%;
    font-weight: 600;
    color: var(--cl-pink);

    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }

  &__title {
    color: var(--cl-blue);
    transition: color 0.3s ease;

    &.heading-4-second {
      font-size: ac(28px, 20px);
    }

    &:not(:last-child) {
      margin-bottom: ac(48px, 30px);
    }
  }

  &__buttons {
    margin-top: auto;
  }

  &:hover {
    .blog-card {
      &__title {
        color: var(--cl-pink);
      }

      &__image {
        /*img {
          transform: scale(1.04);
        }*/

        img {
          filter: grayscale(100);
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }
}
